import React, { useState, useEffect, useMemo } from 'react';
import DetectRTC from 'detectrtc';
import { Pane, Text, Spinner, majorScale, minorScale, Icon } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';

export default function useMicrophoneCheck() {
  // isCameraChecking
  // isCameraSupported
  // isCameraTroubleshoot
  // isCameraDone

  // catch hook on isCameraDone == true

  const [ isMicrophoneChecking, setIsMicrophoneChecking ] = useState(false);
  const [ isMicrophoneSupported, setIsMicrophoneSupported ] = useState(null);
  const [ isMicrophoneTroubleshoot, setIsMicrophoneTroubleshoot ] = useState(false);
  const [ isMicrophoneDone, setMicrophoneIsDone ] = useState(false);

  const { t } = useTranslation();

  useEffect(
    () => {
      if (isMicrophoneChecking === false) return;

      DetectRTC.load(function() {
        if (DetectRTC.hasMicrophone === false) {
          setTimeout(() => {
            setIsMicrophoneSupported(false);
            setIsMicrophoneChecking(false);
          }, 500);
        } else {
          setTimeout(() => {
            setIsMicrophoneSupported(true);
            setIsMicrophoneChecking(false);
          }, 500);
        }
      });
    },
    [ isMicrophoneChecking ]
  );

  useEffect(
    () => {
      if (isMicrophoneSupported === null) return;

      if (isMicrophoneSupported === false) {
        setIsMicrophoneTroubleshoot(true);
        return;
      }

      setMicrophoneIsDone(true);
    },
    [ isMicrophoneSupported ]
  );

  const microphoneCheckingUI = useMemo(
    () => {
      return (
        <React.Fragment>
          {isMicrophoneChecking ? (
            <React.Fragment>
              <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Text size={500} marginRight={minorScale(2)} marginTop={minorScale(2)}>
  3 - 
{' '}
{t`Checking Microphone`}
                </Text>
                <Spinner size={minorScale(4)} />
              </Pane>
            </React.Fragment>
          ) : (
            ''
          )}
          {isMicrophoneDone ? (
            <React.Fragment>
              <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Text size={500} marginRight={minorScale(2)} marginTop={minorScale(2)}>
  3 - 
{' '}
{t`Checking Microphone`}
                </Text>
                {isMicrophoneSupported ? (
                  <Icon size={minorScale(4)} icon="tick" color="success" />
                ) : (
                  <Icon size={minorScale(4)} icon="cross" color="danger" />
                )}
              </Pane>
            </React.Fragment>
          ) : (
            ''
          )}
          {isMicrophoneTroubleshoot ? (
            <React.Fragment>
              <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Text size={500} marginRight={minorScale(2)} marginTop={minorScale(2)}>
  3 - 
{' '}
{t`Checking Microphone`}
                </Text>
                <Icon size={minorScale(4)} icon="cross" color="danger" />
              </Pane>
              <Pane
                paddingLeft={majorScale(1)}
                paddingRight={majorScale(1)}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                marginTop={majorScale(2)}
                marginBottom={majorScale(1)}
              >
                <Text textAlign={'center'}>
                  {t`Microphone not detected`}
                  <br />
                  <br />
                  {t`Please make sure a microphone is connected`}
                </Text>
              </Pane>
            </React.Fragment>
          ) : (
            ''
          )}
        </React.Fragment>
      );
    },
    [ isMicrophoneChecking, isMicrophoneDone, isMicrophoneSupported, isMicrophoneTroubleshoot ]
  );

  return {
    setIsMicrophoneChecking,
    isMicrophoneDone,
    microphoneCheckingUI
  };
}
