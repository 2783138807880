import axios, {CancelToken} from 'axios';

const teleconsultaAPI = (function() {
	const __DEV__ = process.env.NODE_ENV === 'development';
	const __STAGING__ = window.location.hostname === 'nimbo-teleconsulta-staging.herokuapp.com';

	const API_PORT = __DEV__ ? 3030 : 443;
	const API_PROT = __DEV__ ? 'http://' : 'https://';
	const API_HOST = window.location.hostname;
	const API_URL = `${API_PROT}${API_HOST}:${API_PORT}`;
	const TELECONSULTA_URL = __DEV__ ? API_URL : __STAGING__ ? 'https://nimbo-teleconsulta-staging.herokuapp.com' : 'https://teleconsulta.chat'
	const NIMBOX_URL = __DEV__ ? 'localhost:4200/' : __STAGING__ ? 'https://nimbo-x-staging.firebaseapp.com' : 'https://app.nimbo-x.com';
	const NIMBOX_API_URL = __DEV__ ? 'localhost:5000/' : __STAGING__ ? 'https://nimbox-api-staging.herokuapp.com' : 'https://nimbox-api-production.herokuapp.com';
	const SOCKETIO_URL = __DEV__ ? 'http://localhost:3030/' : __STAGING__ ? 'https://nimbo-teleconsulta-staging.herokuapp.com/' : 'https://teleconsulta.chat/';
	// TODO: Change this url when nimbochat api is integrated into teleconsulta
	//const NIMBOCHAT_API_URL = __DEV__ ? 'http://localhost:3001/' : __STAGING__ ? 'https://nimbochat-staging.joeloya.io/' : 'https://api.nimbo.chat/';
	const NIMBOCHAT_API_URL = __DEV__ ? 'https://nimbochat-staging.joeloya.io' : __STAGING__ ? 'https://nimbochat-staging.joeloya.io' : 'https://api.nimbo.chat';

	// nimbox api endpoints
	const getOrganizationInfo = async (orgID) => {
		const {data} = await axios.get(`${NIMBOX_API_URL}/api/v1/organizations/${orgID}/waiting_room_information`, {
			timeout: 15000
		});
		return data;
	}

	const saveWaitingRoomConfiguration = async (nimboxToken, configuration) => {
		let endpoint = `${NIMBOX_API_URL}/api/v1/organizations/me`;

    let req = {
			method: 'put',
			url: endpoint,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${nimboxToken}`
			},
			data: configuration
		};

		const {data} = await axios(req);
		return data;
  }

	// telehealth endpoints
	const nimboxMetric = async (metric, waitingRoomId) => {
		const endpoint = `${API_URL}/api/nimbo/telehealth_waiting_room/${waitingRoomId}/metrics`

		let reqData = {events: {}};
		reqData.events[metric] = "true";

		let req = {
			method: 'put',
			url: endpoint,
			headers: {
				'Content-Type': 'application/json'
			},
			data : reqData
		};

		const {data} = await axios(req);
	}

	const trackEvent = async (event, orgID=0) => {
		const endpoint = `${API_URL}/api/app/track?orgID=${orgID}`;

		const {data} = await axios.post(endpoint,
      {
        event: event,
      },
      {
        timeout: 15000
      }
    );

		return data;
	}

	const getOnlineDoctors = async (orgId) => {
		const {data} = await axios.get(`${API_URL}/api/teleconsulta/organization/${orgId}/online`, {
			timeout: 15000
		});
		return data;
	}

	const getMeetingRoomInfo = async (roomName) => {
		const {data} = await axios.get(`${API_URL}/api/nimbo/meeting/${roomName}`, {
			timeout: 15000
		});
		return data;
	}

	const getTelehealthRoomInfo = async (roomName) => {
		const {data} = await axios.get(`${API_URL}/api/nimbo/telehealth_waiting_room/${roomName}`, {
			timeout: 15000
		});
		return data;
	}

	const getDoctorInfo = async(nimbox_token) => {
		const {data } = await axios.get(`${API_URL}/api/nimbo/doctor?token=${nimbox_token}`, {
			timeout: 15000
		});
		return data;
	}

	const getPatientInfo = async(nimbox_token, patientId) => {
		const { data } = await axios.get(`${API_URL}/api/nimbo/patients/${patientId}?token=${nimbox_token}`, {
			timeout: 15000
		});
		return data;
	};

	const refreshPendingPatients = async (nimbox_token) => {
		const source = CancelToken.source();

		const promise = await axios.get(`${API_URL}/api/nimbo/telehealth/pendingpatients?token=${nimbox_token}`, {
			timeout: 15000,
			cancelToken: source.token
		});

		promise.cancel = () => {
			source.cancel('Query was cancelled by React Query');
		};

		return promise;
	};

	const refreshScheduledPatients = async (nimbox_token, startdate) => {
		const { data } = await axios.get(`${API_URL}/api/nimbo/telehealth/scheduledpatients?token=${nimbox_token}&startdate=${startdate}`, {
			timeout: 15000
		});
		return data;
	};

	const refreshPastPatients = async (nimbox_token) => {
		const { data } = await axios.get(`${API_URL}/api/nimbo/telehealth/pastpatients?token=${nimbox_token}`, {
			timeout: 15000
		});
		return data;
	};

	const refreshLobby = async (hashID) => {
		const { data } = await axios.get(`${API_URL}/api/nimbo/telehealth/check?id=${hashID}`, {
			timeout: 15000
		});
		return data;
	};

	const callNextPatient = async (nimbox_token) => {
		const { data } = await axios.get(`${API_URL}/api/nimbo/telehealth/next?token=${nimbox_token}`, {
			timeout: 15000
		});
		return data;
	};

	const callPendingPatient = async (nimbox_token, waiting_room_id) => {
		const {
			data
		} = await axios.get(`${API_URL}/api/nimbo/telehealth/specific?token=${nimbox_token}&id=${waiting_room_id}`, {
			timeout: 15000
		});
		return data;
	};

	return {
		getOrganizationInfo,
		saveWaitingRoomConfiguration,
		refreshScheduledPatients,
		refreshPastPatients,
		refreshPendingPatients,
		refreshLobby,
		getDoctorInfo,
		getPatientInfo,
		getMeetingRoomInfo,
		getTelehealthRoomInfo,
		getOnlineDoctors,
		callNextPatient,
		callPendingPatient,
		nimboxMetric,
		trackEvent,
		TELECONSULTA_URL,
		NIMBOX_URL,
		SOCKETIO_URL,
		NIMBOCHAT_API_URL,
	};
})();

async function nimboxMetric(metric, waitingRoomId){
	try {
		const data = await teleconsultaAPI.nimboxMetric(metric, waitingRoomId);
		console.log({metric: data});
	}catch (e){
		console.warn('nimboxMetric()', e);
	}
}

async function trackEvent(event, orgID=0){
	try {
		await teleconsultaAPI.trackEvent(event,orgID);
	}catch (e) {
		console.warn('trackEvent()', e);
	}
}

export default teleconsultaAPI;
export {trackEvent, nimboxMetric};

/*

// Example of an abortable api method

const abortable_api_endpoint = (parameter, onSuccess, onError) => {
  let request = new XMLHttpRequest();
  request.responseType = 'json';

  request.addEventListener('load', () => {
    onSuccess(request.response);
  });

  request.addEventListener('error', () => {
    onError(request.response);
  });

  request.open('GET', `${API_URL}/api/endpoint?parameter=${parameter}`);
  request.send();

  return request;
};

// Example of calling the abortable api method

useEffect(
  () => {
    if (param === null) return;

    let request = api.abortable_api_endpoint(
      param,
      (successResponse) => {
        //success
        console.log(successResponse);
      },
      (errorResponse) => {
        //error
        console.log(errorResponse);
      }
    );

    return () => {
      request.abort();
    };
  },
  [ param ]
);
*/
