import React from "react";

const SVG = ({
  style = {},
  fill = "#FFF",
  width = "40%",
  viewBox = "0 0 100.0 100"
}) => (
  <svg   
    width={width}
    style={style}
    height={width}
    fill={fill} 
    viewBox={viewBox} 
    xmlns="http://www.w3.org/2000/svg" 
    x="0px"
    y="0px"
  >
    <path d="M900,1000 c60,0,100,-40,100,-100 l0,-600 c0,-70,-40,-100,-100,-100 l-800,0 c-60,0,-100,40,-100,100 l0,600 c0,60,30,100,100,100 Z M1100,715 l250,184 l0,-600 l-250,180 Z M334.0,854.0 l0,-504.0 l378.0,252.0 Z M334.0,854.0 " stroke="none" stroke-dasharray="none" stroke-linecap="inherit" stroke-linejoin="inherit" stroke-width="1" transform="matrix(0.05,0.0,0.0,-0.05,20.0,80.0)"></path>
  </svg>
);

export default SVG;
