import React from "react";

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  viewBox = "0 0 86 86"
}) => (
      <svg 
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle fill="#D9E4EB" cx="43" cy="43" r="43"/>
          <path d="M56.785 39.533s6.473 20.475-2.711 25.865c-8.328 4.886-22.252-2.722-23.066-5.313-.813-2.59-4.504-24.635.794-26.287 9.42-2.939 22.219-3.067 24.983 5.735" fill="#F49C84"/>
          <path d="M63.114 27.717c-1.073-1.838-2.927-3.255-5.033-3.673-2.018-.4-4.268-.027-6.014-1.113-.453-.281-.844-.649-1.287-.943-1.442-.954-3.333-1.05-5-.585-1.667.465-3.144 1.435-4.51 2.495-.999.776-1.985 1.626-3.186 2.032-2.199.745-4.613-.143-6.922.095-2.638.272-5.04 2.149-5.935 4.635-.914 2.538-.345 5.513-1.655 7.874-.681 1.228-1.83 2.19-2.315 3.506-.648 1.76.03 3.725.934 5.37.832 1.515 1.84 2.94 2.324 4.618.357 1.24.545 2.517.96 3.743.808 2.39 2.284 4.54 5.008 4.783.968.086 2.033.147 2.819-.423.775-.563 1.05-1.582 1.165-2.53.19-1.549.081-3.127-.294-4.64.939.507 1.824 1.352 2.875 1.2.346-.051.576-.321.824-.569 2.264-2.255-.306-5.905-.573-8.262-.295-2.586 2.139-5.125 4.745-4.953 1.132.074 2.228.576 3.358.485 2.217-.176 3.696-2.49 5.85-3.035 1.99-.502 4.094.055 6.097.096 3.46.07 6.605-3.044 6.736-6.49.05-1.308-.315-2.59-.97-3.716" fill="#122744"/>
          <path d="M35.258 53.179c-.817-1.843-2.244-4.066-4.48-2.814-.79.443-1.23 1.387-1.538 2.302-1.024 3.043 1.365 7.74 4.633 7.305 1.595-.212 2.478-1.941 3.127-3.366" fill="#F49C84"/>
          <path d="M50.756 48s2.635 5.055 2.194 6.104c-.59 1.405-2.95.738-2.95.738" stroke="#C45F47" strokeWidth=".75"/><path d="M49 57.871L55.968 57s.52 5.123-2.614 5.897C49.502 63.85 49 57.871 49 57.871" fill="#FFF"/>
          <path d="M56.194 46.351c.068.473.14.95.23 1.415.03.157.14.274.242.221.102-.053.167-.218.138-.39-.079-.47-.178-.935-.278-1.394-.03-.134-.12-.24-.212-.19-.092.046-.142.187-.12.338" fill="#0A1B43"/>
          <path d="M54 43.868s1.613-1.408 4-.329" stroke="#0A1B43" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M44.22 49.347c.051.475.106.953.18 1.421.024.16.142.27.241.22.107-.056.161-.215.138-.386-.062-.47-.144-.938-.228-1.4-.025-.138-.123-.237-.212-.19-.096.047-.136.184-.12.335" fill="#0A1B43"/>
          <path d="M46 45.093s-2.474-.731-5 1.907" stroke="#0A1B43" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>
  );
export default SVG;
        