import React, {useEffect, useState, useRef} from "react";
import io from 'socket.io-client'; 
import teleconsultaAPI from '../helpers/teleconsultaAPI';

import { useTranslation } from 'react-i18next';
// implement socket-io-server depending on environment
// localhost, http://localhost:3000
// staging, https://nimbo-teleconsulta-staging.herokuapp.com/
// production, https://teleconsulta.chat/

// affiliation_id
// id

export default function OnlineUsers(props) {
  const { t } = useTranslation();
  const [doctor, setDoctor] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState(1);
  const [doctorIsOffline, setDoctorIsOffline] = useState(null);


  const socket = useRef(null);

  useEffect( async () => {
    const doc = await teleconsultaAPI.getDoctorInfo(props.token);
    //console.log('doc', doc);
    setDoctor(doc);
  },[]);

  useEffect( () => {
    if (doctor === null) return;
    if (socket.current !== null) return;

    //console.log(doctor);
    console.log('Is this called twice?');

    const doctorId = doctor.account.id;
    const doctorOrganizationId = doctor.organization.id;

    //console.log(`${doctorId}-${doctorOrganizationId}`);

    socket.current = io(teleconsultaAPI.SOCKETIO_URL);

    socket.current.on('connect', function () {
        socket.current.emit('join', `${doctorId}-${doctorOrganizationId}`);
    });

    socket.current.on('ONLINE_USERS', function (data) {
      setOnlineUsers(data);
    });

    return () => {
      socket.current.disconnect();
      socket.current = null;
    }
  }, [doctor]);

  useEffect(() => {
    if (doctorIsOffline === null) return;
    if (socket.current === null) return;

    if (doctorIsOffline){
      socket.current.emit('MARK_AS_OFFLINE')
    }else {
      socket.current.emit('MARK_AS_ONLINE')
    }

  }, [doctorIsOffline]);

  const handleCheckbox = (e) => {
    const markAsOnline = e.target.checked;
    setDoctorIsOffline(markAsOnline);
  }

  return (
    <div className="onlineUsers-container">
      <div>
        <div>
          <span>{onlineUsers}</span>
        </div>
        <div>
          <span>
          { 
          onlineUsers === 1 
          ? 'Doctor Online'
          : 'Doctores En Linea'
          }
          </span>
        </div>
      </div>
      <div>
        <div>
        <span>
        {t`show-me-off`}
        </span>
        <input onClick={(e) => handleCheckbox(e)} type="checkbox" />
        </div>
      </div>
      <style jsx>{`
      .onlineUsers-container {
        width:100%;
        min-width: 168px;
        height: 110px;
        border: 1px solid #e0e8ef;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        margin-right: 12px;
        border-radius: 8px;
        box-sizing: border-box;
       }
       .onlineUsers-container > div:first-child > div:first-child {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        font-size: 48px;
        color: rgb(0, 123, 230);
        box-sizing: border-box;
        padding-left: 24px;
        padding-right: 4px;
        min-width: 80px;
      }
      .onlineUsers-container > div:first-child > div:first-child span {
        width: 100%;
        text-align: center;
      }
      .onlineUsers-container > div:first-child > div:last-child {
        min-width: 100px;
        font-size: 18px;
        box-sizing: border-box;
        padding-left: 4px;
        padding-right: 24px;
        color: rgb(68, 98, 115);
        line-height: 1.25;
        justify-content: flex-start;
      }
      .onlineUsers-container > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
      }
      .onlineUsers-container > div:last-child {
        position: absolute;
        font-size: 12px;
        width: 130px;
        height: 110px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        align-self: flex-end;
      }
      .onlineUsers-container > div:last-child > div {
        height: 20px;
        background-color: rgba(0, 123, 230, 0.9);
        color: #EEEEEE;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      `}
      </style>
    </div>
  );
}