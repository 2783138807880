import React from "react";
import Moment from "react-moment";
import Linkify from "react-linkify";

export default (props) => {
  const cssBubbleType =
    props.direction === "outgoing" ? "outgoing" : "incoming";

  return (
    <>
      <div className={`chatMessage ${cssBubbleType}`}>
        <div className={`chatBubble ${cssBubbleType}`}>
          <Linkify>{props.messageContent}</Linkify>
        </div>
        <div className={`chatBubbleMeta ${cssBubbleType}`}>
          <span style={{ marginLeft: "8px" }}>
            <Moment element='span' format='LT' local>
              {props.timestamp}
            </Moment>
          </span>
        </div>
      </div>

      <style jsx global>{`
          div.chatBubble.outgoing a {
            color: white;
          }
          div.chatMessage{
              margin-bottom: 16px;
              max-width: 75%;
              min-width: 150px;
          }
          div.chatBubble{
              padding: 12px;
              background-color: white;
              border-radius: 5px 15px 15px 2px;
              border: 1px solid rgb(241,244,247)
              color: rgb(111,119,127);
              font-size: 14px;
          }
          div.chatBubbleMeta{
              margin-top: 4px;
              font-size: 12px;
              color: rgb(209,214,219);
              text-align: right;
              padding-right: 6px;
          }
          div.chatBubbleMeta.incoming{
            text-align:left;
          }
          div.chatMessage.outgoing{
            align-self: flex-end;
          }
          div.chatBubble.outgoing {
            background-color: rgb(40, 120, 251);
            color: rgb(255, 255, 255);
            border-radius: 15px 5px 2px 15px;
          }
          `}</style>
    </>
  );
};
