import React from "react";
import { useState, useEffect } from "react";
import MuteIcon from "../icons/MuteIcon";

const __DEV__ = process.env.NODE_ENV === 'development';

export default function ToggleAudioButton(props) {
  const [enabled, setEnabled] = useState(true);

  const buttonStatus = enabled ? "enabled" : "disabled";
  const fillColor = enabled ? "#FFF" : "#000";

  useEffect(()=> {
    if (__DEV__){
      setEnabled(false);
    }
  }, []);

  function handleClick() {
    setEnabled(enabled=> !enabled);
    props.onClick();
  }

  return (
    <div className={`option ${buttonStatus}`} onClick={handleClick}>
      <MuteIcon fill={fillColor} />
    </div>
  );
};