import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { CookiesProvider } from 'react-cookie';
import DoctorDashboard from './pages/DoctorsDashboard';
import Landing from './pages/Landing';
import Lobby from './pages/w';
import Meeting from './pages/Meeting';
import OauthReverse from './pages/nimbochat/oauth/reverse';
import NimboChat from './pages/nimbochat/index';
import { useTranslation } from 'react-i18next';
import './i18n';
import './index.css';
import * as Sentry from '@sentry/react';

Sentry.init({dsn: "https://c0b44582c98b4241828e3c280da2708f@o417367.ingest.sentry.io/5316922"});

export default function App() {
	const { i18n } = useTranslation();

	useEffect(
		() => {
			// If accesing the website through teleconsulta.chat
			// set default lang as spanish
			const current_host = window.location.hostname;
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const lang = urlParams.get('lang')
		
			i18n.changeLanguage(lang);
		
			
		},
		[ i18n ]
	);

	return (
		<CookiesProvider>
			<Router>
				<Switch>
					<Route path="/webchat/oauth/reverse">
						<OauthReverse />
					</Route>
					<Route path="/webchat">
						<NimboChat />
					</Route>
					<Route path="/dashboard">
						<DoctorDashboard />
					</Route>
					<Route path="/w/:id">
						<Lobby />
					</Route>
					<Route path="/:id">
						<Meeting />
					</Route>
					<Route path="/">
						<Landing />
					</Route>
				</Switch>
			</Router>
			<style jsx global>
				{`
					body {
						background-color: rgb(246, 249, 250);
						color: rgb(59, 70, 88);
						font-family: 'Proxima Nova Regular', Helvetica, sans-serif;
					}
					a {
						color: #0176fe;
					}
				`}
			</style>
		</CookiesProvider>
	);
}

ReactDOM.render(<App />, document.getElementById('root'));
