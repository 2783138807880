import React from "react";

const SVG = ({
  style = {},
  fill = "#F5CB23",
  width = "100%",
  className = "",
  viewBox = "-8 -9 35 35"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    fill={fill}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'>
    <path
      d='M14.9543 5.8038a.9378.9378 0 00-.7453-.636l-3.9645-.626L8.3473.5361A.9372.9372 0 007.5 0a.9372.9372 0 00-.8473.5361L4.7555 4.5418l-3.9645.626a.9378.9378 0 00-.7453.636.939.939 0 00.2285.9529l2.9274 2.9265-.8403 4.1953a.9375.9375 0 00.3668.941.9397.9397 0 00.5532.1805.9273.9273 0 00.4546-.1184L7.5 12.791l3.764 2.0906a.9273.9273 0 00.4548.1184.9368.9368 0 00.9187-1.1215l-.839-4.1953 2.9273-2.9265a.939.939 0 00.2285-.9529'
      fill-rule='evenodd'
    />
  </svg>
);

export default SVG;
