import React from 'react';
import { useEffect, useContext, useState } from 'react';
/*eslint no-warning-comments: "error"*/

import CallHelpModal from '../components/meeting/CallHelpModal';
import ToggleAudioButton from '../components/meeting/ToggleAudioButton';
import ToggleVideoButton from '../components/meeting/ToggleVideoButton';
import HangUpButton from '../components/meeting/HangUpButton';
import AvatarIcon from '../components/icons/AvatarIcon';

import PreflightContextProvider, { PreflightContext } from '../contexts/PreflightContext';
import PeerContextProvider, { PeerContext } from '../contexts/peer';
import { getRoomName } from '../helpers/utils';

import { Pane, TextInput, Button, Text, Icon } from 'evergreen-ui';

import { useTranslation } from 'react-i18next';
import teleconsultaAPI, {trackEvent, nimboxMetric} from "./../helpers/teleconsultaAPI";

const __DEV__ = process.env.NODE_ENV === 'development';

export default function MeetingContainer() {

	return (
		<PreflightContextProvider>
			<PeerContextProvider>
				<Meeting />
			</PeerContextProvider>
		</PreflightContextProvider>
	);
}

function Meeting() {
	const [ isFa, setIsFa ] = useState(false);
	const [ orgID, setOrgID ] = useState(undefined);
	const [ helpModal, setHelpModal ] = useState(false);
	const [ currentMessage, setCurrentMessage ] = useState('');
	const [ chatActive, setChatActive ] = useState(false);
	const [ meetingID, setMeetingID ] = useState(null);
	
	const pendingOrgIdQueue = React.useRef([]);

	const { localStream, localVideoRef, remoteStream, remoteVideoRef, setLocalStreamError } = useContext(
		PreflightContext
	);

	const {
		handleAudioToggle,
		handleVideoToggle,
		handleHangUp,
		remoteVideoStopped,
		localVideoStopped,
		roomMessages,
		remoteConnection,
		sendMessage
	} = useContext(PeerContext);

	const roomName = getRoomName();

	const { t } = useTranslation();

	async function getOrgID(roomName) {
		try {
			const _roomInfo = await teleconsultaAPI.getTelehealthRoomInfo(roomName);
			const orgId = _roomInfo.organization_id;
			setOrgID(orgId);
			nimboxMetric("entered_teleconsultation", _roomInfo.id);
		}catch (e) {
			console.log("error requesting room info", roomName, e);
			setOrgID(0);
		}
	}

	async function clearOrgIdQueue(){
		while (pendingOrgIdQueue.current.length > 0) {
			const pendingEvent = pendingOrgIdQueue.current.shift();
			trackEvent(pendingEvent, orgID);
		}
	}

	async function trackEventWithOrgId(eventName){
		if (orgID === undefined){
			console.log("No orgID, queueing ", eventName);
			pendingOrgIdQueue.current.push(eventName);
		}else{
			trackEvent(eventName, orgID);
		}
	}

	useEffect(()=> {
		if (orgID === undefined) return;
		clearOrgIdQueue();
	}, [orgID]);

	useEffect(() => {
		// Check base64 room for FA account
		getOrgID(roomName);
		trackEventWithOrgId('USER_ENTERED_MEETING');
		// try to decode roomName
		// MTcxNjMyNi0yNDM4MQ --> 1716326-24381
		let decodedData = '';
		try {
			decodedData = window.atob(roomName);
		} catch (e) {
			console.warn('NOT_BASE_64', e);
			return;
		}

		// 1716326-24381 --> [1716326,24381]
		const [ _meetingID, accountId ] = decodedData.split('-');
		if (!accountId) return;
		// 24731

		if (_meetingID){
			setMeetingID(_meetingID);
		}

		if (accountId.startsWith('2472')) {
			setIsFa(true);
		}
		if (accountId.startsWith('2473')) {
			setIsFa(true);
		}
		if (accountId.startsWith('2474')) {
			setIsFa(true);
		}
		if (accountId.startsWith('2475')) {
			setIsFa(true);
		}
	}, []);

	// First thing in the UI is to paint your camera
	useEffect(
		() => {
			if (localStream === null) return;
			if (localVideoRef.current === null) return;

			trackEventWithOrgId("USER_GOT_LOCALSTREAM");
			
			// if it already has a source object, leave it alone
			if (localVideoRef.current.srcObject) return;

			localVideoRef.current.srcObject = localStream;
			setTimeout(() => {
				let promise = undefined;

				if (localVideoRef.current) {
					try {
						localVideoRef.current.play();
					}catch (e){
						console.log("couldn't autoplay localvideo", e);
					}
				}

				/*
      if (promise !== undefined) {
        promise && promise.then(_ => {
          // Autoplay started!
        }).catch(error => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
          setLocalStreamError(true);
        });
      }
      */
			}, 2500);

			if (__DEV__) {
				handleAudioToggle();
			}
		},
		[ localStream, localVideoRef ]
	);

	useEffect(
		() => {
			if (remoteStream === null) return;

			trackEventWithOrgId("USER_GOT_REMOTESTREAM");

			remoteVideoRef.current.srcObject = remoteStream;
			remoteVideoRef.current.play();
		},
		[ remoteStream ]
	);

	useEffect(
		() => {
			updateScroll();
		},
		[ roomMessages ]
	);

	// UI STEPS
	const connectionEstablished = localStream && remoteStream;
	const waiting = !connectionEstablished;

	const localVideoCss = localVideoStopped ? 'local-video-stopped' : '';

	function updateScroll() {
		const element = document.getElementById('chatMessagesID');
		if (element) element.scrollTop = element.scrollHeight;
	}

	const handleHelp = () => {
		setHelpModal(show => !show);
	}

	return (
		<div className="App">
			{
				helpModal
				? (
					<CallHelpModal
						roomName={roomName}
						onBackPress={handleHelp} 
						onHelpPatientPress={() => {
							remoteConnection && sendMessage('Doctor tengo problemas de conexión.\nPodra marcar a mi telefono?');
						}}/>
				)
				: null
			}
			<div className={chatActive ? 'chat active' : 'chat'}>
				<Pane
					paddingLeft={'16px'}
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					width="100%"
				>
					<Pane
						className="logo"
						minHeight="80px"
						maxHeight="120px"
						marginRight={'16px'}
						marginTop={'8px'}
						display="flex"
						alignItems="flex-start"
						flexDirection="column"
						justifyContent="space-between"
						paddingTop={8}
						paddingBottom={8}
						boxSizing={'border-box'}
					>
						{isFa ? (
							<img width="50%" src="/images/falogo.png" />
						) : (
							<img width="65%" src="/images/nimbologo2.png" />
						)}
						{ 
						meetingID
						? <span><a className="help-link" href="#" onClick={handleHelp}>¿Problemas con la llamada?</a></span> 
						: null
						}
					</Pane>
					<Pane
						id="chatMessagesID"
						className="chatMessages"
						backgroundColor="white"
						flexDirection="column"
						marginRight={'16px'}
					>
						{roomMessages ? (
							roomMessages.map((message) => {
								return (
									<Text
										key={message.content}
										className={`messageBubble ${message.kind}`}
										marginBottom={'8px'}
									>
										{message.content}
									</Text>
								);
							})
						) : (
							''
						)}
					</Pane>
					<Pane className="messageArea" width="100%">
						<TextInput
							className="messageInput"
							value={currentMessage}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									remoteConnection && sendMessage(currentMessage);
									setCurrentMessage('');
								}
							}}
							onChange={(e) => setCurrentMessage(e.target.value)}
							placeholder={t`Write a message here`}
						/>
						<Button
							appearance="primary"
							intent={isFa ? 'danger' : 'none'}
							marginLeft={'8px'}
							marginRight={'16px'}
							disabled={remoteConnection ? false : true}
							onClick={() => {
								remoteConnection && sendMessage(currentMessage);
								setCurrentMessage('');
							}}
						>
							{t`SEND`}
						</Button>
					</Pane>
				</Pane>
			</div>
			<div className="media">
				<div id="local-media" className={localVideoCss}>
					<div
						id="chat-button"
						onClick={() => {
							setChatActive((active) => !active);
						}}
					>
						{chatActive ? <Icon size={30} icon="mobile-video" /> : <Icon size={30} icon="chat" />}
					</div>
					<video playsInline autoPlay muted ref={localVideoRef} className={localVideoCss} />
				</div>
				<div id="remote-media">
					{remoteVideoStopped ? (
						<div className="video_overlay">
							<AvatarIcon />
						</div>
					) : (
						<React.Fragment />
					)}
					{waiting ? (
						<div className="waitingForMedia">
							{isFa ? (
								<img height="350px" src="/images/medical-waiting-room-fa.png" />
							) : (
								<img height="300px" src="/images/medical-waiting-room.png" />
							)}
							{isFa ? <h1>Preparando Teleorientación</h1> : <h1>{t`Setting Up Teleconsultation`}</h1>}
							<h2>
								{t`Waiting for one more person`}
								.
							</h2>
						</div>
					) : (
						<video playsInline autoPlay ref={remoteVideoRef} />
					)}
				</div>
				<div className="options">
					{connectionEstablished ? (
						<React.Fragment>
							<ToggleAudioButton onClick={() => handleAudioToggle()} />
							<HangUpButton onClick={handleHangUp} />
							<ToggleVideoButton onClick={handleVideoToggle} />
						</React.Fragment>
					) : (
						<React.Fragment />
					)}
				</div>
			</div>
			<style jsx global>
				{`
					@media (max-width: 768px) {
						.App {
							display: block !important;
							flex-diretion: none !important;
							position: relative !important;
							background-color: black;
						}
						.chat {
							position: fixed !important;
							width: 100vw !important;
							height: 100% !important;
							background: rgb(246, 249, 250);
							z-index: 1 !important;
						}
						.chat.active {
							z-index: 3 !important;
						}
						.chat.active + .media {
							background-color: transparent !important;
							z-index: 4 !important;
							height: auto !important;
						}
						.chat.active + .media #remote-media {
							display: none;
							pointer-events: none;
						}
						.chat .chatMessages {
							margin-top: 20px !important;
						}
						.chat .messageArea {
							padding-top: 10px !important;
							align-items: flex-start !important;
						}
						.media {
							position: absolute !important;
							width: 100vw !important;
							height: 100vh !important;
							background: rgb(246, 249, 250) !important;
							z-index: 2 !important;
						}
						.logo {
							display: none;
						}
						#local-media {
							width: 80px !important;
							height: 80px !important;
							position: absolute !important;
							right: 16px !important;
							top: 16px !important;
							border-radius: 10px !important;
							border-top-left-radius: 0px !important;
							border-bottom-left-radius: 0px !important;
							z-index: 4 !important;
							box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
						}
						#local-media video {
							width: 80px !important;
							height: 80px !important;
							border-radius: 10px !important;
							border-top-left-radius: 0px !important;
							border-bottom-left-radius: 0px !important;
							object-fit: cover !important;
							z-index: 4 !important;
						}
						#local-media #chat-button {
							position: absolute;
							width: 60px;
							height: 80px;
							border-top-left-radius: 10px;
							border-bottom-left-radius: 10px;
							background-color: rgb(244, 244, 244);
							left: -60px;
							box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
							display: flex !important;
							justify-content: center;
							align-items: center;
							cursor: pointer;
							z-index: 4 !important;
						}
						#local-media #chat-button:hover {
							background-color: rgb(222, 222, 222);
						}
						.options {
							bottom: 16vh !important;
						}
						#remote-media video {
							height: 95vh !important;
						}
						.messageInput {
							font-size: 16px;
						}
					}
					.help-link{
						color: #444444;
						text-decoration: none;
					}
					.help-link:hover{
						text-decoration: underline;
					}
					.App {
						width: 100vw;
						margin: 0 auto;
						display: flex;
						flex-direction: row;
					}
					.logo {
						flex-shrink: 0;
					}
					.chat {
						display: flex;
						width: 30%;
						min-width: 256px;
						height: 100vh;
						max-height: 100vh;
						position: relative;
					}
					.chatMessages {
						flex-grow: 1;
						display: flex;
						border-radius: 3px;
						border: 1px solid transparent;
						border-top: none;
						border-bottom: 1px solid #ddd;
						box-shadow: inset 0 1px 3px rgba(0, 0, 0, .70), 0 -1px 1px #fff, 0 1px 0 #fff;
						padding: 16px;
						overflow-y: scroll;
						font-size: 12px;
						z-index: 3;
					}
					.messageArea {
						min-height: 64px;
						height: 64px;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: row;
						flex-shrink: 0;
					}
					.messageBubble {
						background: rgba(0, 0, 0, 0.05);
						padding: 12px;
						border-radius: 10px;
						box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
						max-width: 85%;
						min-width: 50%;
						z-index: 1;
					}
					.messageBubble.system {
						font-weight: 500;
						max-width: 65%;
					}
					.messageBubble.local {
						align-self: flex-end;
						text-align: right;
						background: none;
					}
					.messageBubble.remote {
						align-self: flex-start;
					}
					.messageInput {
						flex-grow: 1;
					}
					.media {
						display: flex;
						width: 70%;
						min-width: 400px;
						position: relative;
					}
					#local-media {
						width: 144px;
						height: 144px;
						position: absolute;
						right: 16px;
						top: 16px;
						border-radius: 10vw;
						z-index: 3;
					}
					#local-media video {
						width: 144px;
						height: 144px;
						border-radius: 25vw;
						object-fit: cover;
						z-index: 3;
					}
					#local-media #chat-button {
						display: none;
					}
					div.local-video-stopped {
						background-color: black;
					}
					video.local-video-stopped {
						filter: blur(3px);
					}
					#remote-media {
						width: 100%;
						height: 100%;
					}
					#remote-media video {
						width: 100%;
						height: 100vh;
						object-fit: cover;
						z-index: 1;
					}
					.video_overlay {
						position: absolute;
						background-color: black;
						color: white;
						width: 100%;
						height: 100vh;
						z-index: 2;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.waitingForMedia {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						height: 100vh;
					}
					body {
						margin: 0;
						background-color: rgb(246, 249, 250);
					}
					.options {
						position: absolute;
						bottom: 20px;
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: center;
						z-index: 3;
					}
					.options > div {
						display: flex;
					}
					.options .option {
						width: 75px;
						height: 75px;
						margin-left: 15px;
						margin-right: 15px;
						border-radius: 75px;
						display: flex;
						justify-content: center;
						align-items: center;
						user-select: none;
					}
					.enabled {
						background-color: black;
						border: 1px solid black;
						opacity: 0.45;
						color: white;
					}
					.disabled {
						background-color: white;
						border: 1px solid white;
						color: white;
						opacity: 0.95;
						color: black;
					}
					.media h1 {
						font-family: 'Proxima Nova Semibold', Helvetica, sans-serif;
						color: rgb(59, 70, 88);
						font-size: 24px;
						padding: 16px;
					}
					.media h2 {
						font-family: Helvetica, sans-serif;
						color: rgb(59, 70, 88);
						font-size: 20px;
						padding: 16px;
					}
				`}
			</style>
		</div>
	);
}
