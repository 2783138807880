import React, {useState, useEffect} from "react";
import {useParameter, _try} from "../../../helpers/utils";
import useLocalStorage from "../../../helpers/useLocalStorage";
import nimbochatAPI from "../../../helpers/nimbochatAPI";

export default function OauthReverse() {
    const provider = useParameter("provider");
    const token = useParameter("token");

    let [error, setError] = useState(false);
    let [user, setUser] = useState(null);
    let [$user, $setUser] = useLocalStorage('user', null);

    useEffect(() => {
        async function oauthLogin (token) {
            try {
              let nimboxUser = await nimbochatAPI.getAccountInfo(token);
              let cellphoneNationalNumber = _try(()=> nimboxUser.account.cellphone, undefined);
              let cellphoneCountryId = _try(()=> nimboxUser.account.cellphone_country_id, undefined);
              let cellphoneIsVerified = _try(()=> nimboxUser.account.cellphone_verified, false);
              let drName = _try(()=> nimboxUser.account.name, undefined);

              if (!/^\d+$/.test(cellphoneCountryId)){
                throw new Error("INVALID_COUNTRY_ID");
              }

              if (!cellphoneIsVerified){
                //throw new Error("CELLPHONE_NOT_VERIFIED");
              }

              let countryInfo = await nimbochatAPI.getCountryInfo(token, cellphoneCountryId);
              let cellphoneCountryCode = _try(()=> countryInfo.country.country_code, undefined);

              let drE164 = `+${cellphoneCountryCode.trim()}${cellphoneNationalNumber.trim()}`;

              // check if cellphone is valid
              if(!/^\+\d{7,15}/.test(drE164)){
                throw new Error("INVALID_USER_CELLPHONE");
              }

              let user = {
                userE164: drE164,
                userName: drName,
                token: token
              }

              // save it globally
              $setUser(user);

              // save it for the local component as well
              setUser(user);
            } catch (e) {
              console.log("NIMBOCHAT_SERVER_ERROR");
              console.log(e);
              setError(true);
            }
        }
        
        oauthLogin(token);
    }, []);

    useEffect(() => {
        if (user === null) return;
        
        window.location.href = `/webchat/`;
    }, [user]);

    const loading = (user === null) && (error === false);

    if (loading) {
        return (
          <>
            <div>
              <img height='130px' src='/images/loading.gif' />
            </div>
            <style jsx global>
            {`
                body{
                    background-color: white !important;
                }
            `}
            </style>
            <style>
              {`
              div{
                overflow: hidden;
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center; /*centers items on the line (the x-axis by default)*/
                align-items: center; 
              }
              `}
            </style>
          </>
        );
      }

    if (error) {
        return (
          <>
            <div>
              <img height='300px' src='/images/nimbochat-error.png' />
              <h1>Ups! Error Inesperado</h1>
              <h2>Favor de contactar con soporte</h2>
            </div>
            <style jsx global>
            {`
                body{
                    background-color: white !important;
                }
            `}
            </style>
            <style>
              {`
              div{
                overflow: hidden;
                position: absolute;
                width: 100%;
                height: 85%;
                display: flex;
                flex-direction: column;
                justify-content: center; /*centers items on the line (the x-axis by default)*/
                align-items: center; 
                font-family: "Proxima Nova Semibold", "Helvetica", sans-serif;
              }
              h2{
                font-family: "Proxima Nova Regular", "Helvetica", sans-serif;
    
              }
              `}
            </style>
          </>
        );
      }

    return <React.Fragment/>;
}