import React from "react";
import { useState } from "react";
import CamIcon from "../icons/CamIcon";


export default function ToggleVideoButton(props) {
  const [enabled, setEnabled] = useState(true);

  const buttonStatus = enabled ? "enabled" : "disabled";
  const fillColor = enabled ? "#FFF" : "#000";

  function handleClick() {
    setEnabled(enabled=> !enabled);
    props.onClick(enabled);
  }
  
  return (
    <div className={`option ${buttonStatus}`} onClick={handleClick}>
      <CamIcon fill={fillColor} />
    </div>
  );
};