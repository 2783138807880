import React from "react";

const getInitials = (name, fallback = "?") => {
  if (!name || typeof name !== "string") return fallback;
  return name
    .replace(/\s+/, " ")
    .split(" ") // Repeated spaces results in empty strings
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase()) // Watch out for empty strings
    .join("");
};

const Avatar = (props) => (
  <>
    <div className={`avatar ${props.size}`}>
      <span className='initials'>{getInitials(props.name)}</span>
    </div>
    <style jsx>
      {`
        .avatar {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: lightgrey;
          text-align: center;
        }
        .avatar.xl {
          width: 30px;
          height: 30px;
        }
        .initials {
          font-size: 10px; /* 50% of parent */
          line-height: 1;
          position: relative;
          top: 1px;
          font-family: "Proxima Nova Semibold";
        }
        .avatar.xl .initials {
          font-size: 13px; /* 50% of parent */
          line-height: 1;
          position: relative;
          top: 4px;
          font-family: "Proxima Nova Semibold";
        }
      `}
    </style>
  </>
);

export default Avatar;