import React from "react";

const SVG = ({
  style = {},
  fill = "#FFF",
  width = "40%",
  viewBox = "0 0 565 565"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fill={fill}
      d='M494.942 247.471v-35.353h-70.706v35.353c0 22.296-5.653 43.121-14.884 61.898l51.804 51.804c21.122-32.917 33.786-71.735 33.786-113.702zM388.882 247.471V106.059C388.882 47.575 341.307 0 282.823 0c-49.558 0-90.948 34.298-102.531 80.31l202.179 202.18c3.888-11.02 6.411-22.685 6.411-35.019zM565.647 515.656L49.991 0 0 49.991l176.765 176.765v20.715c0 58.485 47.575 106.059 106.059 106.059 6.443 0 12.662-.805 18.762-1.953l28.705 28.705c-14.865 5.386-30.75 8.601-47.467 8.601-77.974 0-141.412-63.439-141.412-141.412v-35.353H70.706v35.353c0 104.896 76.61 192.012 176.765 208.925v109.253h70.706v-109.25c23.328-3.938 45.076-12.142 65.102-23.127L515.657 565.65l49.991-49.991z'
    />
  </svg>
);

export default SVG;
