import React, { useState, useEffect, useMemo } from 'react';
import DetectRTC from 'detectrtc';
import { Pane, Text, Spinner, majorScale, minorScale, Icon } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';

export default function WebRTCCheck() {
  const [ isChecking, setIsChecking ] = useState(false);
  const [ isSupported, setIsSupported ] = useState(null);
  const [ isTroubleshoot, setIsTroubleshoot ] = useState(false);
  const [ isDone, setIsDone ] = useState(false);

  const { t } = useTranslation();

  useEffect(
    () => {
      if (isChecking === false) return;

      DetectRTC.load(function() {
        if (DetectRTC.isWebRTCSupported === false) {
          setTimeout(() => {
            setIsSupported(false);
            setIsChecking(false);
          }, 500);
        } else {
          setTimeout(() => {
            //true
            setIsSupported(true);
            setIsChecking(false);
          }, 500);
        }
      });
    },
    [ isChecking ]
  );

  useEffect(
    () => {
      if (isSupported === null) return;

      if (isSupported === false) {
        setIsTroubleshoot(true);
        return;
      }

      setIsDone(true);
    },
    [ isSupported ]
  );

  const checkingUI = useMemo(
    () => {
      return (
        <React.Fragment>
          {isChecking ? (
            <React.Fragment>
              <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Text size={500} marginRight={minorScale(2)} marginTop={minorScale(2)}>
  4 - 
{' '}
{t`Checking WebRTC`}
                </Text>
                <Spinner size={minorScale(4)} />
              </Pane>
            </React.Fragment>
          ) : (
            ''
          )}
          {isDone ? (
            <React.Fragment>
              <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Text size={500} marginRight={minorScale(2)} marginTop={minorScale(2)}>
  4 - 
{' '}
{t`Checking WebRTC`}
                </Text>
                {isSupported ? (
                  <Icon size={minorScale(4)} icon="tick" color="success" />
                ) : (
                  <Icon size={minorScale(4)} icon="cross" color="danger" />
                )}
              </Pane>
            </React.Fragment>
          ) : (
            ''
          )}
          {isTroubleshoot ? (
            <React.Fragment>
              <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Text size={500} marginRight={minorScale(2)} marginTop={minorScale(2)}>
  4 - 
{' '}
{t`Checking WebRTC`}
                </Text>
                <Icon size={minorScale(4)} icon="cross" color="danger" />
              </Pane>
              <Pane
                paddingLeft={majorScale(1)}
                paddingRight={majorScale(1)}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                marginTop={majorScale(2)}
                marginBottom={majorScale(1)}
              >
                <Text textAlign={'center'}>
                  <br />
                  {t`Your device can't make video calls`}
                  <br />
                  {t`Please try from a different device`}
                </Text>
              </Pane>
            </React.Fragment>
          ) : (
            ''
          )}
        </React.Fragment>
      );
    },
    [ isChecking, isDone, isSupported, isTroubleshoot ]
  );

  return {
    setIsChecking,
    isDone,
    checkingUI
  };
}
