import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

export default function Landing() {
	const { t } = useTranslation();
	const [cookies, setCookie] = useCookies();


	return (
		<React.Fragment>
			<h1>{t('Teleconsultation')}</h1>
			<h1>{`${cookies.browserHasPassedPreflight}`}</h1>
			<span>Powered by nimbox</span>
		</React.Fragment>
	);
}
