import React, { useState, useEffect, createContext, useRef } from "react";
import nimbochatAPI from "../helpers/nimbochatAPI";

export const ChatContext = createContext(null);

// TODO: Replace with use-query
function useInterval (callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick () {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function ChatContextProvider (props) {
  let [userId, setUserId] = useState(-1);
  let [userName, setUserName] = useState("");
  let [token, setToken] = useState("");
  let [channelId, setChannelId] = useState(-1);
  let [activeChannelTitle, setActiveChannelTitle] = useState("");
  let [activeChannelId, setActiveChannelId] = useState(null);
  let [channels, setChannels] = useState([]);
  let [messages, setMessages] = useState([]);
  let [activeSidebar, setActiveSidebar] = useState('channels');
  let [refetchIntervalMS, setRefetchIntervalMS] = useState(5000);

  const fetchChannels = async () => {
    if (!token) {
      return;
    }

    try {
      const json = await nimbochatAPI.getChannels(token);
      setChannels(json);
    }catch (e) {
      console.error('nimbochatAPI.getChannels error', e);
    }
  };

  const fetchMessages = async () => {
    if (!token || !activeChannelId) {
      return;
    }

    const firstE164 = userId;
    const secondE164 = activeChannelId;

    try {
      let json = await nimbochatAPI.getMessages(firstE164, secondE164, token);    
      setMessages(json);
    }catch (e) {
      console.error('nimbochatAPI.getMessages error', e);
    }
  };

  const setFirstChannelAsActive = async () => {
    if (channels[0]) {
      const firstChannel = channels[0];
      setActiveChannelId(firstChannel.chatid);
      setActiveChannelTitle(firstChannel.fullname);
    }
  };

  useEffect(() => {
    setActiveChannelId(null);
    fetchChannels();
  }, [token]);

  useEffect(() => {
    if (activeChannelId === null) {
      setFirstChannelAsActive();
    }
  }, [channels]);

  useEffect(() => {
    fetchMessages();
  }, [activeChannelId]);

  useInterval(() => {
    fetchChannels();
    fetchMessages();
  }, refetchIntervalMS);


  const chatContextAPI = {
    channelId,
    setChannelId,
    token,
    setToken,
    userId,
    setUserId,
    userName,
    setUserName,
    activeChannelId,
    setActiveChannelId,
    channels,
    setChannels,
    messages,
    setMessages,
    fetchChannels,
    fetchMessages,
    activeChannelTitle,
    setActiveChannelTitle,
    activeSidebar,
    setActiveSidebar,
    setRefetchIntervalMS
  };

  return (
    <ChatContext.Provider value={chatContextAPI}>
      {props.children}
    </ChatContext.Provider>
  );
}