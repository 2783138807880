import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Chip from '@mui/material/Chip';


import moment from 'moment-timezone';
import Button from '../components/Nimbutton';
import NextIcon from '../components/icons/NextIcon';
import { Spinner, Icon } from 'evergreen-ui';
import teleconsultaAPI from '../helpers/teleconsultaAPI';
import { _try } from '../helpers/utils';
import { useLocation } from 'react-router-dom';
import { useQuery, queryCache } from 'react-query';
import { useTranslation } from 'react-i18next';
import OnlineUsers from '../components/OnlineUsers';
import DashboardConfiguration from '../components/DashboardConfiguration';
import { Divider } from '@mui/material';

function useURLParam(param) {
	const params = new URLSearchParams(useLocation().search);
	return params.get(param);
}

const getScheduledPatients = async (key, token, startdate) => {
	const response = await teleconsultaAPI.refreshScheduledPatients(token, startdate);
	//console.log('getScheduledPatients', startdate, response);

	const allAppointments = response.consultation_schedules.filter(schedule => {
		const startsAt = moment.parseZone(schedule.starts_at);
		const utfOffset = startsAt.utcOffset();
		const now = moment().utcOffset(utfOffset);
		if (now.isAfter(startsAt)){
			return false;
		}
		if (schedule.schedule_type !== "telehealth"){
			return false;
		}
		return true;
	});
	const allConfirmedAppointments = allAppointments.filter(schedule =>schedule.telehealth_url && schedule.telehealth_url.length > 0);
	const unconfirmedAppointments = allAppointments.length - allConfirmedAppointments.length;

	const scheduledPatients = {
		appointments: allConfirmedAppointments.slice(0,3),
		unconfirmedAppointments
	}

	//console.log(scheduledPatients);

	return scheduledPatients;
};

const getPatientInfo = async (token, patientid) => {
	const response = await teleconsultaAPI.getPatientInfo(token, patientid);
	//console.log(patientid, response);
	return response;
};

const getPendingPatients = async (key, token) => {
	const promise = teleconsultaAPI.refreshPendingPatients(token);
	return promise;
};

const getPastPatients = async (key, token) => {
	const response = await teleconsultaAPI.refreshPastPatients(token);
	console.log('getPastPatients', response);
	return response.telehealth_waiting_rooms.slice(-3).reverse();
};

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));

// Had to wrap in an additional wrap to allow scrolling because iframes
// from nimbox come with the scrolling=no property
export default function DoctorsDashboardIframeWrap() {
	const originalRequestResource = window.document.location.href;
	let wrappedInsideIframe = useURLParam('isWrapped');

	useEffect(() => {
		//console.log('originalRequestResource?', originalRequestResource);
		//console.log('wrappedInsideIframe?', Boolean(wrappedInsideIframe));
	}, []);

	if (Boolean(wrappedInsideIframe)) {
		return <DoctorsDashboard />;
	} else {
		return (
			<React.Fragment>
				<iframe src={`${originalRequestResource}&isWrapped=true`} title="Dashboard" />
				<style jsx global>
					{`
						iframe {
							border-box: box-sizing;
							width: 100vw;
							height: 100vh;
							border: 0px;
						}
						body {
							overflow: hidden;
						}
					`}
				</style>
			</React.Fragment>
		);
	}
}

function DoctorsDashboard() {
	const [ isLoadingNextPatient, setIsLoadingNextPatient ] = useState(false);
	const [ isLoadingSpecificPatient, setIsLoadingSpecificPatient ] = useState(false);
	const [ doc, setDoc] = useState(null);
	const [ waitingRoomConfiguration, setWaitingRoomConfiguration ] = useState(null);

	let token = useURLParam('token');
	let startdate = moment().format('YYYY-MM-DD');

	const {data: scheduledPatients } = useQuery(['scheduledPatients', token, startdate], getScheduledPatients, {
		onSuccess: () => {
			setTimeout(() => {
				//refetch in 100 seconds
				queryCache.refetchQueries('scheduledPatients');
			}, 100000);
		},
		retry: 3,
		retryDelay: (attemptIndex) => Math.min(1000 * 7 * attemptIndex, 30000),
		refetchOnWindowFocus: true
	});

	const {data: scheduledPatientsInfo } = useQuery(
		() => ['scheduledPatientsInfo', scheduledPatients.appointments],
		async () => {
			return await Promise.all(scheduledPatients.appointments.map(async (scheduledPatient) => {
				const data = await getPatientInfo(token, scheduledPatient.person_id)
				return data;
			}));
		}
	)

	const { data: pendingPatients } = useQuery([ 'pendingPatients', token ], getPendingPatients, {
		onSuccess: () => {
			console.log('onSuccess: getPendingPatients', pendingPatients);

			setTimeout(() => {
				//refetch in 30 seconds
				queryCache.refetchQueries('pendingPatients');
			}, 60000);
		},
    onError: (error) => {
      console.log('onError: pendingPatients', error);
    },
		retry: 3,
		retryDelay: (attemptIndex) => Math.min(1000 * 2 * attemptIndex, 30000),
		refetchOnWindowFocus: true
	});

	// This will only re-trigger when pendingPatients changes (its listed as a dependency)
	const { data: pastPatients } = useQuery([ 'pastPatients', token ], getPastPatients, {
		onSuccess: () => {
			console.log('pastPatients', pastPatients);
			setTimeout(() => {
				//refetch in 30 seconds
				queryCache.refetchQueries('pastPatients');
			}, 60000);
		},
		retry: 6,
		retryDelay: (attemptIndex) => Math.min(1000 * 2 * attemptIndex, 30000),
		refetchOnWindowFocus: true
	});

	const { t } = useTranslation();

	useEffect(() => {
		async function fetchData(){
			try {
				const _doc = await teleconsultaAPI.getDoctorInfo(token);
				setDoc(_doc);
			} catch (e) {
				console.log("error requesting doc info", e);
			}
		}
		fetchData();
	}, [token]);

	useEffect(() => {
		const wrConfig = _try(() => doc.organization.waiting_room_configuration, null);
		setWaitingRoomConfiguration(wrConfig);
	}, [doc])

	useEffect(() => {}, []);

	const handleNextPatient = async () => {
		if (isLoadingNextPatient) return;
		setIsLoadingNextPatient(true);

		// return early if theres no patients in the waiting list
		if (pendingPatients) {
			if (pendingPatients.data.meta.total_pending === 0) {
				setTimeout(() => {
					alert('No hay ningun paciente en la lista de espera todavia');
				}, 200);
				setTimeout(() => {
					setIsLoadingNextPatient(false);
				}, 500);
				return;
			}
		}

		try {
			const response = await teleconsultaAPI.callNextPatient(token);

			const videocall_url = response.telehealth_waiting_room.telehealth_url;
			if (!videocall_url) {
				throw Error('empty telehealth_url');
			}

			window.open(videocall_url, '_blank');
		} catch (e) {
			console.log(e);
			alert(`Error inesperado al llamar siguiente paciente\n${e}`);
		} finally {
			setTimeout(() => {
				setIsLoadingNextPatient(false);
			}, 500);
		}
	};

	const handlePendingPatient = async (waiting_room_id) => {
		if (isLoadingSpecificPatient) return;
		setIsLoadingSpecificPatient(true);

		try {
			const response = await teleconsultaAPI.callPendingPatient(token, waiting_room_id);

			const videocall_url = response.telehealth_waiting_room.telehealth_url;
			if (!videocall_url) {
				throw Error('empty telehealth_url');
			}
			window.open(videocall_url, '_blank');
		} catch (e) {
			console.log(e);
			alert(`Error inesperado al llamar paciente\n${e}`);
		} finally {
			setTimeout(() => {
				setIsLoadingSpecificPatient(false);
			}, 500);
		}
	};

	const orgSlug = _try(()=> doc.organization.telehealth_request_slug, null);
	const orgWaitingRoomURL = _try(() => `${teleconsultaAPI.NIMBOX_URL}/o/${orgSlug}`, null)

	return (
		<React.Fragment>

<h3 className="text-5xl font-bold underline">
      Hello world!
    </h3>
			<div className="doctors-dashboard">

		
				<h1>{t`telehealth-dashboard`}</h1>


				
				{
					orgSlug
					?	<h3 style={{marginTop: '6px'}}>
							<a href={orgWaitingRoomURL} target="_blank" rel="noopener noreferrer">
							{t`waiting-room`} <Icon marginBottom={-2} icon={'document-open'}/>
							</a>
						</h3>
					: null
				}


<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={0}>
						<Grid xs={12} sm={3} md={2} xl={2} >
						<div className="summary">
						<div>
							<span>{pendingPatients && pendingPatients.data.meta.total_pending}</span>
						</div>
						<div>
							<span>
								{pendingPatients ? pendingPatients.data.meta.total_pending === 1 ? (
									t`patient-on-wt`
								) : (
									t`patients-on-wt`
								) : null}
							</span>
						</div>
					</div>
						</Grid>
						<Grid xs={12} sm={3} md={2} xl={1.2}>
							<OnlineUsers token={token}/>
						</Grid>
						<Grid xs={12} sm={3} md={2} xl={1} >
						<div className={isLoadingNextPatient ? 'next-patient loading' : 'next-patient'}
							onClick={handleNextPatient}>
							<div className="status">
								<span>{t`next`}</span>
							</div>
							<div className="icon">
								{isLoadingNextPatient ? (
									<Spinner marginTop={'1.0rem'} size={'2.5rem'} />
								) : (
									<NextIcon fill="rgb(0,129,255)" width="4.5rem" />
								)}
							</div>
						</div>
						</Grid>
					</Grid>
				</Box>

				<div className="telehealth-list scheduled">
					<h4>
						{scheduledPatients &&
							Array.isArray(scheduledPatients.appointments) && scheduledPatients.appointments.length === 0 &&
							t`without-patients` }
						 {scheduledPatients && Array.isArray(scheduledPatients.appointments) && scheduledPatients.appointments.length > 0 &&
							'Pacientes Agendados' }
							{scheduledPatients && typeof scheduledPatients.unconfirmedAppointments === 'number' &&  scheduledPatients.unconfirmedAppointments > 0 && ' \u00a0–\u00a0 '}
							<a href={`${teleconsultaAPI.NIMBOX_URL}/agenda/daily`} target="_top">
								{scheduledPatients && typeof scheduledPatients.unconfirmedAppointments === 'number' && scheduledPatients.unconfirmedAppointments === 1 && '1 cita por confirmar'}
								{scheduledPatients && typeof scheduledPatients.unconfirmedAppointments === 'number' && scheduledPatients.unconfirmedAppointments > 1 && `${scheduledPatients.unconfirmedAppointments} citas por confirmar`}
							</a>
					</h4>
					{scheduledPatients &&
						scheduledPatients.appointments.map((scheduledPatient, index) => {
							return (
								<TelehealthPatient
									key={scheduledPatient.id}
									date={scheduledPatient.starts_at}
									scheduledPatient={true}
									patient={_try(()=> scheduledPatientsInfo[index], {})}
									cause={scheduledPatient.cause}
									dateStyle="datetime"
									optionsRender={
										scheduledPatient.telehealth_url === '' ?
											<Button 
												iconBefore="plus"
												onClick={() => handlePendingPatient(scheduledPatient.id)}
											>
												{t`start-consultation`}
											</Button> : 
											<Button
												height={32}
												appearance="minimal"
												iconBefore="document-open"
												is="a"
												href={scheduledPatient.telehealth_url}
												target="_blank"
											>
												{t`consultation-link`}
											</Button>
									}
								/>
							);
						})}
				</div>
				<div className="telehealth-list pending">
					<h4>
						{pendingPatients &&
							Array.isArray(pendingPatients.data.telehealth_waiting_rooms) &&
							pendingPatients.data.telehealth_waiting_rooms.length > 0 &&
							t`patients-waiting`}
					</h4>
				
					{pendingPatients &&
						pendingPatients.data.telehealth_waiting_rooms.map((pendingPatient) => {
							return (
								<TelehealthPatient
									key={pendingPatient.id}
									date={pendingPatient.created_at}
									cause={pendingPatient.cause}
									patient={pendingPatient}
									dateStyle="minutes"
									optionsRender={
										<Button
											iconBefore="plus"
											onClick={() => handlePendingPatient(pendingPatient.id)}
										>
											{isLoadingSpecificPatient ? <Spinner size={'16px'} /> : t`start-consultation`}
										</Button>
									}
								/>
							);
						})}
				
				</div>
				<div className="telehealth-list past">
					<h4>{pastPatients && Array.isArray(pastPatients) && pastPatients.length > 0 && t`last-patients`}</h4>
			
					
					{pastPatients &&
						pastPatients.map((pastPatient) => {
							return (
								<TelehealthPatient
									key={pastPatient.id}
									date={pastPatient.created_at}
									cause={pastPatient.cause}
									patient={pastPatient}
									dateStyle="datetime"
									optionsRender={
										<>
										<Button
											height={32}
											appearance="minimal"
											iconBefore="document-open"
											is="a"
											href={pastPatient.telehealth_url}
											target="_blank"
										>
											{t`consultation-link`}
										</Button>
										</>
									}
								/>
							);
						})}
					
				</div>
			</div>
			<style jsx global>
				{`
					.telehealth-list.past .list-item:nth-child(2) {
						filter: brightness(0.98) grayscale(0.5);
					}
					.telehealth-list.past .list-item:nth-child(3) {
						filter: brightness(0.98) grayscale(0.75);
					}
					.telehealth-list.past .list-item:nth-child(4) {
						filter: brightness(0.98) grayscale(0.99);
					}
					.telehealth-list {
						margin-top: 16px;
					}
					.telehealth-list h4 {
						margin-bottom: 16px;
						font-weight: 600;
						font-size: 13px;
						padding-left: 8px;
						color: #7f95bb;
						text-transform: uppercase;
					}
					.cause span:first-child {
						display: inline-block;
						padding: 5px 15px;
						margin-top:5px;
						border-radius: 20px;
						background-color: rgba(1, 118, 254, 0.25);
						text-decoration: none;
						text-transform: uppercase;
						font-size: 14px;
						color: #0176fe;
					}
					.metadata {
						display: flex;
						flex-direction: row;
						width: 100%;
						margin-top: 2px;
						padding-right: 16px;
						box-sizing: border-box;
						font-weight: 500;
						color: #7f95bb;
						font-size: 14px;
					}
					.metadata .gender {
						margin-left: 8px;
					}
					.metadata .telephone{
						margin-left: 8px;
						font-weight: 600;
					}
					.list-item {
						color: #011429;
						width: 100%;
						border-radius: 5px;
						border: 1px solid #d9e4eb;
						background-color: white;
						border-left-color: rgb(123, 219, 119);
						border-left-width: 6px;
						margin-right: 16px;
						box-sizing: border-box;
						
						margin-bottom: 8px;
					}
					.list-item-column {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						justify-content: center;
						height: inherit;
						min-width: 80px;
						border-right: 2px solid #ecf2f5;
					
					
					}
					.list-item-column.time {
						font-weight: 600;
						letter-spacing: 1.5px;
						color: rgb(52, 68, 92);
					}
					.list-item-column.time span:last-child {
						font-weight: 500;
						color: #7f95bb;
						font-size: 14px;
						letter-spacing: 0px;
						margin-top: 2px;
					}
					.list-item-column.person {
						font-weight: 600;
						color: rgb(52, 68, 92);
						padding-left: 12px;
					}
					.list-item-column.cause {
						flex-grow: 1;
						text-align: left;
						justify-content: center;
						border-right: 0px;
						padding-left: 16px;
						padding-right: 16px;
					}
					}
					.list-item-column.options {
						align-items: center;
						justify-content: center;
						padding-right: 14px;
					}
					.list-item::before {
						top: -1px;
						left: -1px;
						content: '';
						height: calc(100% + 2px);
						width: 6px;
						border-left-color: rgb(123, 219, 119);
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}
					body {
						padding-top: 1rem;
						font-family: 'Proxima Nova Regular', Helvetica, sans-serif;
						background-color: rgb(246, 249, 250);
						color: rgb(59, 70, 88);
					}
					.doctors-dashboard {
						width: 100%;
						box-sizing: border-box;
						padding-left: 30px;
						padding-right: 30px;
						margin-bottom: 48px;
					}
					h1,
					h2,
					h3 {
						margin: 0;
						font-family: 'Proxima Nova Semibold', Helvetica, sans-serif;
					}
					.telehealth-controls {
						display: flex;
						flex-direction: row;
						margin-top: 32px;
					}
					.next-patient.loading {
						border: 2px solid rgba(67, 90, 111, 0.47);
						filter: greyscale(1);
					}
					.next-patient {
						cursor: pointer;
						box-sizing: border-box;
						width: 100%;
						min-width: 130px;
						height: 110px;
						transition: background-color border .15s;
						background-color: white;
						border: 2px solid #007be6;
						margin-right: 12px;
						filter: drop-shadow(3px 4px 2px rgba(0, 129, 255, 0.4));
						display: flex;
						align-items: flex-start;
						justify-content: center;
						border-radius: 8px;
						user-select: none;
					}
					.next-patient:hover {
						filter: drop-shadow(3px 4px 2px rgba(0, 129, 255, 0.4)) brightness(0.99) grayscale(0.15);
					}
					.next-patient:active {
						filter: drop-shadow(3px 4px 2px rgba(0, 129, 255, 0.4)) brightness(0.92) grayscale(0.6);
					}
					.next-patient .status {
						position: absolute;
						width: 100%;
						min-width: 128px;
						height: 92px;
						display: flex;
						justify-content: center;
						align-items: flex-end;
						z-index: 2;
						box-sizing: border-box;
					}
					.next-patient .status span {
						width: 100%;
						text-align: center;
						padding-top: .5rem;
						padding-bottom: .5rem;
						font-family: 'Proxima Nova Semibold', Helvetica, sans-serif;
						font-weight: 700;
						font-size: 16px;
						color: #007be6;
						border-radius: 8px;
						text-transform: capitalize;
					}
					.next-patient .icon {
						filter: none;
						display: flex !important;
						justify-content: center;
						align-items: center;
						margin-top: 4px;
						width: 7rem;
					}
					.summary {
						width:100%;
						min-width: 250px;
						height: 110px;
						border: 1px solid #e0e8ef;
						background-color: white;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						font-size: 32px;
						margin-right: 12px;
						border-radius: 8px;
						box-sizing: border-box;
					}
					.summary div:first-child {
						flex-grow: 1;
						display: flex;
						justify-content: flex-end;
						font-size: 48px;
						color: rgb(0, 123, 230);
						box-sizing: border-box;
						padding-left: 24px;
						padding-right: 4px;
						min-width: 80px;
					}
					.summary div:last-child {
						font-size: 18px;
						box-sizing: border-box;
						padding-left: 4px;
						padding-right: 24px;
						color: rgb(68, 98, 115);
						line-height: 1.25;
						justify-content: flex-start;
					}

					.element {
						padding: 15px;
					}
				`}
			</style>
		</React.Fragment>
	);
}

function TelehealthPatient(props) {
	const { t } = useTranslation();

	const patient = props.patient;

	const genders = {
		m: 'male',
		f: 'female'
	};

	const createdAt = moment.parseZone(props.date);
	const createdAtUTCOffset = createdAt.utcOffset();
	const now = moment().utcOffset(createdAtUTCOffset);
	const minutesPassed = now.diff(createdAt, 'minutes');

	return (

		

			<Grid className="list-item" container>
				<Grid xs={12} sm={12} md={1} xl={1} className="element list-item-column time"
				 >

				{	props.dateStyle === 'minutes' ? (
					<React.Fragment>
						<span>+{minutesPassed} min</span>
						<span>{t`waiting`}</span>
					</React.Fragment>
				) : props.dateStyle === 'datetime' ? (
					<React.Fragment>
						<span>{createdAt.format('hh:mm A')}</span>
						<span>{createdAt.format('D MMM')}</span>
					</React.Fragment>
				) : (
					<span>{createdAt.format('hh:mm A')}</span>
				)
				}
			
			</Grid>
			<Grid xs={12} sm={12} md={5} xl={5} className="list-item-column person element">
				<div>
					{_try(() => patient.person.first_name)} {_try(() => patient.person.last_name)}
				</div>
				<div className="metadata">
					<div className="dob">
						{_try(() => moment().diff(moment(patient.person.born_at, 'YYYY-MM-DD'), 'years'))}{' '}
						{t`years old`}
					</div>
					<div className="gender">{_try(() => t(genders[patient.person.gender]))}</div>
					<div className="telephone">
					{_try(() => patient.person.telephone2)}
					</div>
				</div>
			
			</Grid>
			<Grid xs={12} sm={12} md={4} xl={2.8} className="element">
			<div className='cause'>
				<span>{props.cause}</span>
			</div>
			</Grid>
			<Grid xs={12} sm={12} md={2} xl={3} className="element">
			<div>{props.optionsRender}</div>
			</Grid>
			</Grid>
	
	);
}
