import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// prettier-ignore
i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    en: {
      translation: {
        Teleconsultation: 'Teleconsultation',
        'Camera & Audio Test': 'Camera & Audio Test',
        'This test records your video and audio for 5 seconds.': 'This test records your video and audio for 5 seconds.',
        'At the end you should be able to see and listen yourself.': 'At the end you should be able to see and listen yourself.',
        'Start Test': 'Start Test',
        'Skip Test': 'Skip Test',
        'Audio/Video is OK': 'Audio/Video is OK',
        'Audio/Video is NOT OK': 'Audio/Video is NOT OK',
        'Continue anyway': 'Continue anyway',
        "Your device can't make teleconsultations": "Your device can't make teleconsultations",
        'Please try in a different computer or cellphone': 'Please try in a different computer or cellphone',
        'Browser Test': 'Browser Test',
        'Checking Browser': 'Checking Browser',
        'Your browser is incompatible': 'Your browser is incompatible',
        'Possible Solutions': 'Possible Solutions',
        'Use the default browser in your mobile': 'Use the default browser in your mobile',
        'Safari on iOS': 'Safari on iOS',
        'Chrome on Android': 'Chrome on Android',
        'Open your teleconsultation on a computer': 'Open your teleconsultation on a computer',
        'Please update your browser': 'Please update your browser',
        'Try opening your teleconsultation in a compatible browser': 'Try opening your teleconsultation in a compatible browser',
        'Chrome 69 or newer': 'Chrome 69 or newer',
        'Firefox 71 or newer': 'Firefox 71 or newer',
        'Try opening your teleconsultation on a cellphone or different computer': 'Try opening your teleconsultation on a cellphone or different computer',
        'Checking Camera': 'Checking Camera',
        'Camera not Detected': 'Camera not detected',
        'Please make sure a camera is connected': 'Please make sure a camera is connected',
        'If you have an iPhone, please enable the camera in your settings': 'If you have an iPhone, please make sure the camera is allowed in your settings',
        'Settings > Safari > Camera > Allow': 'Settings > Safari > Camera > Allow',
        'If you have Windows, close all other applications using the camera': 'If you have Windows, close all other applications using the camera',
        'Checking Microphone': 'Checking Microphone',
        'Microphone not detected': 'Microphone not detected',
        'Please make sure a microphone is connected': 'Please make sure a microphone is connected',
        'Checking Permissions': 'Checking Permissions',
        'Refresh this website and make sure to give permissions for camera and microphone': 'Refresh this website and make sure to give permissions for camera and microphone',
        'Checking WebRTC': 'Checking WebRTC',
        "Your device can't make video calls": "Your device can't make video calls",
        'Please try from a different device': 'Please try from a different device',
        'Hi! You can also chat with your doctor here': 'Hi! You can also chat with your doctor here',
        'Write a message here': 'Write a message here',
        'SEND': 'SEND',
        'Setting Up Teleconsultation': 'Teleconsultation',
        'Waiting for one more person': 'Waiting for one more person',
        'Consultation Ended': 'Consultation Ended',
        'Thank you!': 'Thank you!',
        'patients': 'patients',
        'waiting for consultation': 'waiting for consultation',
        'next': 'next',
        'configuration': 'configuration',
        'Patients waiting for consultation': 'Patients waiting for consultation',
        'years old': 'years old',
        'male': 'male',
        'female': 'female',
        'mobile': 'mobile',
        'desktop': 'desktop',
        'marketing assets': 'Marketing Assets',
        'select banner image mobile': 'Select the image for the mobile banner',
        'recommended banner image mobile dimensions': 'The recommended image dimensions are 320px x 50px.',
        'select banner image desktop': 'Select the image for the desktop banner',
        'recommended banner image desktop dimensions': 'The recommended image dimensions are 728px x 90px.',
        'video-validation-message': 'Make sure the YouTube link is correct',
        'save changes': 'Save changes', 
        'waiting-room': 'Waitng Room',
        'patients-on-wt': 'patients on waiting room',
        'patient-on-wt': 'patient on waitng room', 
        'without-patients': 'Without patients',
        'patients-waiting': 'Patients waiting', 
        'start-consultation': 'Start consultation', 
        'waiting': 'waiting', 
        'consultation-link': 'Consultation link',
        'call-link': 'Call link',
        'show-me-off': 'show me offline', 
        'last-patients': 'Last Patients', 
        'telehealth-dashboard': 'Telehealth Dashboard',
        'link-entry-page': 'Entry page link',
        'video-url-entry-page': 'Entry page video',
        'label-entry-page': 'Tag for link',
        'entry-page-section': 'Entry page',
        'link-validation-message': 'Make sure the link is correct',
        'empty-tag-validation': "Tag link can't be empty"
      }
    },
    es: {
      translation: {
        Teleconsultation: 'Teleconsulta',
        'Camera & Audio Test': 'Prueba de Video',
        'This test records your video and audio for 5 seconds.': 'Esta prueba graba tu camara y microfono por 5 segundos.',
        'At the end you should be able to see and listen yourself.': 'Al final deberas poderte ver y escuchar.',
        'Start Test': 'Comenzar Prueba',
        'Skip Test': 'Saltar Prueba',
        'Audio/Video is OK': 'Veo y Escucho',
        'Audio/Video is NOT OK': 'No Veo o Escucho',
        'Continue anyway': 'Continuar de todos modos',
        "Your device can't make teleconsultations": 'Tu dispositivo no puede hacer video llamadas',
        'Please try in a different computer or cellphone': 'Porfavor intenta acceder a la videollamada con otro celular o computadora',
        'Browser Test': 'Prueba de Navegador',
        'Checking Browser': 'Checando Navegador',
        'Your browser is incompatible': 'Tu navegador es incompatible para teleconsultas',
        'Possible Solutions': 'Posibles Soluciones',
        'Use the default browser in your mobile': 'Utiliza el navegador default de tu celular',
        'Safari on iOS': 'Safari en iOS',
        'Chrome on Android': 'Chrome en Android',
        'Open your teleconsultation on a computer': 'Abre tu teleconsulta en una computadora',
        'Please update your browser': 'Porfavor actualiza tu navegador',
        'Try opening your teleconsultation in a compatible browser': 'Intenta abrir tu teleconsulta en otro navegador compatible',
        'Chrome 69 or newer': 'Chrome 69 o superior',
        'Firefox 71 or newer': 'Firefox 71 o superior',
        'Try opening your teleconsultation on a cellphone or different computer': 'Intenta abrir la teleconsulta en tu celular',
        'Checking Camera': 'Checando Camara',
        'Camera not detected': 'Camara no detectada',
        'Please make sure a camera is connected': 'Favor de comprobar que su dispositivo tenga una camara conectada',
        'If you have an iPhone, please enable the camera in your settings': 'Si tienes iPhone asegurate que los permisos de tu camara esten activados',
        'Settings > Safari > Camera > Allow': 'Configuración > Safari > Camara > Permitir',
        'If you have Windows, close all other applications using the camera': 'Si tienes Windows asegurate de no tener otras aplicaciones usando la camara',
        'Checking Microphone': 'Checando Microfono',
        'Microphone not detected': 'Microfono no detectado',
        'Please make sure a microphone is connected': 'Favor de comprobar que tiene un microfono conectado',
        'Checking Permissions': 'Checando Permisos',
        'Refresh this website and make sure to give permissions for camera and microphone': 'Refresca esta pagina y asegurate de otorgar permisos para la camara y microfono',
        'Checking WebRTC': 'Checando WebRTC',
        "Your device can't make video calls": "Tu dispositivo no puede hacer videollamadas",
        'Please try from a different device': 'Intenta desde otra computadora',
        'Hi! You can also chat with your doctor here':  '!Hola! Tambien puedes chatear con tu paciente/doctor',
        'Write a message here': 'Escribe un mensaje aqui',
        'SEND': 'ENVIAR',
        'Setting Up Teleconsultation': 'Preparando Teleconsulta',
        'Waiting for one more person': 'Esperando una persona más',
        'Consultation Ended': 'Consulta Finalizada',
        'Thank you!': '!Gracias!',
        'patients': 'pacientes',
        'waiting for consultation': 'esperando consulta',
        'next': 'siguiente',
        'configuration': 'configuración',
        'Patients waiting for consultation': 'Pacientes en fila de espera',
        'years old': 'años',
        'male': 'hombre',
        'female': 'mujer',
        'mobile': 'móvil',
        'desktop': 'escritorio',
        'marketing assets': 'Material Promocional',
        'select banner image mobile': 'Selecciona la imagen del banner para mobile',
        'recommended banner image mobile dimensions': 'Las dimensiones de la imagen recomendadas son 320px x 50px.',
        'select banner image desktop': 'Selecciona la imagen del banner para desktop',
        'recommended banner image desktop dimensions': 'Las dimensiones de la imagen recomendadas son 728px x 90px.',
        'video-validation-message': 'Revisa que el link de YouTube esta correcto',
        'save changes': 'Guardar cambios',
        'waiting-room': 'Sala de Espera',
        'patients-on-wt': 'Pacientes en la sala de espera',
        'patient-on-wt': 'Paciente en la sala de espera', 
        'without-patients': 'Sin pacientes agendados',
        'patients-waiting': 'Pacientes en espera', 
        'start-consultation': 'Empezar Consulta', 
        'waiting': 'en espera', 
        'consultation-link': 'Liga de consulta',
        'call-link': 'Liga de llamada',
        'show-me-off': 'Aparecer offline',
        'last-patients': 'Ultimos pacientes',
        'telehealth-dashboard': 'Panel de Teleconsulta',
        'link-entry-page': 'Link pagina de entrada',
        'video-url-entry-page': 'Video pagina de entrada',
        'label-entry-page': 'Etiqueta del hipervinculo',
        'entry-page-section': 'Pagina de entrada',
        'link-validation-message': 'Revisa que el link sea correcto',
        'empty-tag-validation': 'La etiqueta del hipervinculo no puede estar en blanco'
      }
    }
  },
  fallbackLng: 'en',
  debug: true,
  lng: 'en',
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  }
});

export default i18n;
