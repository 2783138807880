import React from "react";

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  viewBox = "2 2 25 25"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'>
    <path
      fill={fill}
      d='M21.1428571,19.2857143 L20.2857143,19.2857143 L20.2857143,20.1428571 C20.2857143,20.6168571 19.9025714,21 19.4285714,21 C18.9545714,21 18.5714286,20.6168571 18.5714286,20.1428571 L18.5714286,19.2857143 L17.7142857,19.2857143 C17.2402857,19.2857143 16.8571429,18.9025714 16.8571429,18.4285714 C16.8571429,17.9545714 17.2402857,17.5714286 17.7142857,17.5714286 L18.5714286,17.5714286 L18.5714286,16.7142857 C18.5714286,16.2402857 18.9545714,15.8571429 19.4285714,15.8571429 C19.9025714,15.8571429 20.2857143,16.2402857 20.2857143,16.7142857 L20.2857143,17.5714286 L21.1428571,17.5714286 C21.6168571,17.5714286 22,17.9545714 22,18.4285714 C22,18.9025714 21.6168571,19.2857143 21.1428571,19.2857143 Z M16.978,15.978 C15.9185714,16.296 15.1428571,17.2671429 15.1428571,18.4285714 C15.1428571,19.5471429 15.8602857,20.4882857 16.8571429,20.8422857 L16.8571429,21 L10.8571429,21 C10.384,21 10,20.6168571 10,20.1428571 C10,18.4285714 11.6568571,16.8291429 13.3505714,16.1965714 C12.3725714,15.5922857 11.7142857,14.5191429 11.7142857,13.2857143 L11.7142857,12.4285714 C11.7142857,10.5351429 13.2494286,9 15.1428571,9 C17.0362857,9 18.5714286,10.5351429 18.5714286,12.4285714 L18.5714286,13.2857143 C18.5714286,13.6851429 18.4822857,14.0562857 18.3554286,14.4111429 C17.7031429,14.7214286 17.1888571,15.2742857 16.978,15.978 Z'
    />
  </svg>
);

export default SVG;
