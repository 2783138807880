import React, { useState, useCallback, useEffect, useContext } from 'react';
import Moment from "react-moment";
import Avatar from "./Avatar";
import StarIcon from "../../../components/icons/StarIcon";
import AddUserIcon from "../../../components/icons/AddUserIcon";
import {ChatContext} from "../../../contexts/ChatContext";
import teleconsultaAPI from "../../../helpers/teleconsultaAPI";
import nimbochatAPI from '../../../helpers/nimbochatAPI';

const calendarStrings = {
  lastDay: "[Yesterday]",
  sameDay: "LT",
  nextDay: "LT",
  lastWeek: "DD/MM/YY",
  nextWeek: "DD/MM/YY",
  sameElse: "DD/MM/YY"
};

export default function Channels (props) {
  const {
    activeChannelId,
    setActiveChannelId,
    setActiveChannelTitle,
    setActiveSidebar,
    token,
    userId
  } = useContext(ChatContext);

  useEffect(()=> {
    console.log("channels", props.channels, 'userId', userId);
  }, [props.channels]);

  const onChannelSelection = (chatId, chatTitle) => {
    setActiveChannelId(chatId);
    setActiveChannelTitle(chatTitle);
    console.log("onchannelselection", chatId);
  };

  const handleAddUser = () => {
    setActiveSidebar('contacts');
  }

  // TO-DO: unread messages
  // to_e164_latest_name or from_e164_latest_name depending on current_e164
  return (
    <div className='chatSidebar'>
      <div className='conversationSearchContainer'>
        <input type='text' placeholder='Search chat'></input>
        <a onClick={handleAddUser}>
          <AddUserIcon fill='rgb(49, 115, 254)' />
        </a>
      </div>
      <div className='separator-80'></div>
      <div className='conversationList'>
        {props.channels.map((channel) => {
          const isActive = channel.e164_group === activeChannelId ? "active" : null;
          const channelName = channel.from_e164 === userId ? channel.to_e164_latest_name : channel.from_e164_latest_name;
          const channelID = channel.from_e164 === userId ? channel.to_e164 : channel.from_e164;
          return (
            <div
              onClick={() =>
                onChannelSelection(channelID, channelName)
              }
              key={channel.id}
              className={`conversation ${isActive}`}>
              <div style={{ width: "40px" }}>
                <Avatar name={channelName} />
              </div>

              <div style={{ flexGrow: 1, flex: 1 }}>
                <div className='username'>{channelName}</div>
                <div className='lastMessage' style={{ marginTop: "4px" }}>
                  {channel.message_body}
                </div>
              </div>

              <div className='datetime' style={{ width: "40px" }}>
                <Moment
                  element='span'
                  calendar={calendarStrings}
                  local
                  style={{ color: "rgb(215,220,224)" }}>
                  {channel.message_timestamp}
                </Moment>
              </div>
            </div>
          );
        })}
      </div>
      <style jsx>{`
        div.conversation {
          padding: 12px;
          display: flex;
          border-bottom: 1px solid rgb(241, 244, 247);
        }
        div.conversation.active {
          background-color: rgb(244, 247, 249);
        }
        div.chatSidebar {
          min-width: 300px;
          max-width: 300px;
          flex-shrink: 0;
          flex-direction: column;
          display: flex;
          height: 100%;
        }
        div.chatSidebar .username {
          font-family: "Proxima Nova Semibold", "Helvetica", sans-serif;
          color: rgb(59, 70, 88);
          font-size: 13px;
        }
        div.chatSidebar .lastMessage {
          font-size: 11px;
          font-family: "Proxima Nova Regular";
          color: rgb(113, 114, 132);
        }
        div.slug {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          padding-top: 10px;
          padding-bottom: 0px;
          margin-bottom: 50px;
          font-size: 13px;
          font-family: "Proxima Nova Regular", "Helvetica", sans-serif;
        }
        div.slug a {
          color: #0176fe;
          text-decoration: none;
        }
        div.slug a:hover {
          text-decoration: underline;
        }
        div.conversationSearchContainer {
          min-height: 50px;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
        }
        div.conversationSearchContainer input {
          flex-grow: 1;
          height: 25px;
          padding: 2px;
          padding-left: 8px;
          border: 1px solid rgb(227, 235, 240);
          border-radius: 3px;
        }
        div.conversationSearchContainer a {
          height: 25px;
          padding: 2px;
          margin-left: 8px;
          width: 25px;
          border: 1px solid rgb(49, 115, 254);
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        div.conversationSearchContainer a:hover {
          background-color: rgb(244, 247, 249);
        }
        div.conversationList {
          flex-grow: 1;
          height: 100%;
          overflow-y: auto;
        }
        div.conversationList .datetime {
          font-size: 9px;
        }
        div.separator-80 {
          height: 1px;
          background-color: rgb(241, 244, 247);
          width: 88%;
          margin-left: 6%;
          margin-bottom: 2px;
          flex-shrink: 0;
        }
      `}</style>
    </div>
  );
}