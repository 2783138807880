import React, { useState, useEffect, useMemo } from 'react';
import DetectRTC from 'detectrtc';
import { Pane, Text, Spinner, majorScale, minorScale, Icon } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';

export default function useCameraCheck() {
  const [ isCameraChecking, setIsCameraChecking ] = useState(false);
  const [ isCameraSupported, setIsCameraSupported ] = useState(null);
  const [ isCameraTroubleshoot, setIsCameraTroubleshoot ] = useState(false);
  const [ isCameraDone, setCameraIsDone ] = useState(false);
  const { t } = useTranslation();

  useEffect(
    () => {
      if (isCameraChecking === false) return;

      DetectRTC.load(function() {
        if (DetectRTC.hasWebcam === false) {
          setTimeout(() => {
            setIsCameraSupported(false);
            setIsCameraChecking(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setIsCameraSupported(true);
            setIsCameraChecking(false);
          }, 1000);
        }
      });
    },
    [ isCameraChecking ]
  );

  useEffect(
    () => {
      if (isCameraSupported === null) return;

      if (isCameraSupported === false) {
        setIsCameraTroubleshoot(true);
        return;
      }

      setCameraIsDone(true);
    },
    [ isCameraSupported ]
  );

  const cameraCheckingUI = useMemo(
    () => {
      return (
        <React.Fragment>
          {isCameraChecking ? (
            <React.Fragment>
              <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Text size={500} marginRight={minorScale(2)} marginTop={minorScale(2)}>
  2 - 
{' '}
{t`Checking Camera`}
                </Text>
                <Spinner size={minorScale(4)} />
              </Pane>
            </React.Fragment>
          ) : (
            ''
          )}
          {isCameraDone ? (
            <React.Fragment>
              <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Text size={500} marginRight={minorScale(2)} marginTop={minorScale(2)}>
  2 - 
{' '}
{t`Checking Camera`}
                </Text>
                {isCameraSupported ? (
                  <Icon size={minorScale(4)} icon="tick" color="success" />
                ) : (
                  <Icon size={minorScale(4)} icon="cross" color="danger" />
                )}
              </Pane>
            </React.Fragment>
          ) : (
            ''
          )}
          {isCameraTroubleshoot ? (
            <React.Fragment>
              <Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Text size={500} marginRight={minorScale(2)} marginTop={minorScale(2)}>
  2 - 
{' '}
{t`Checking Camera`}
                </Text>
                <Icon size={minorScale(4)} icon="cross" color="danger" />
              </Pane>
              <Pane
                paddingLeft={majorScale(1)}
                paddingRight={majorScale(1)}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                marginTop={majorScale(2)}
                marginBottom={majorScale(1)}
              >
                <Text textAlign={'center'}>
                  {t`Camera not detected`}
                  <br />
                  <br />
                  {t`Please make sure a camera is connected`}
                  <br />
                  <br />
                  {t`If you have an iPhone, please enable the camera in your settings`}
                  <br />
                  {t`Settings > Safari > Camera > Allow`}
                  <br />
                  <br />
                  {t`If you have Windows, close all other applications using the camera`}
                </Text>
              </Pane>
            </React.Fragment>
          ) : (
            ''
          )}
        </React.Fragment>
      );
    },
    [ isCameraChecking, isCameraDone, isCameraSupported, isCameraTroubleshoot ]
  );

  return {
    setIsCameraChecking,
    isCameraChecking,
    isCameraSupported,
    isCameraTroubleshoot,
    isCameraDone,
    cameraCheckingUI
  };
}
