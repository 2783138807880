import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import ChatBubble from "./ChatBubble";
import Avatar from "./Avatar";
import SendIcon from "../../../components/icons/SendIcon";
import {ChatContext} from "../../../contexts/ChatContext";
import nimbochatAPI from "../../../helpers/nimbochatAPI";

let onNewMessageCallback = () => {};

export default function Chat ({ messages, currentUserId }) {
  const { activeChannelId, userId, activeChannelTitle, userName, token } = useContext(
    ChatContext
  );

  let [chatAreaSize, setChatAreaSize] = useState(0);
  let [newMessage, setNewMessage] = useState("");
  let [lastGuid, setLastGuid] = useState("");

  useEffect(() => {
    function getSize () {
      const currentChatAreaSize = document.querySelector(".messages")
        .offsetWidth;
      console.log("width", currentChatAreaSize);
      setChatAreaSize(currentChatAreaSize);
    }

    function handleResize () {
      getSize();
    }

    getSize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

    // everytime activaChannelId set the onNewMessageCallback
    useEffect(() => {
      onNewMessageCallback = () => {
        const node = document.querySelector(".messages");
        node.scrollTop = node.scrollHeight;
      };
    }, [activeChannelId]);

  // execute and clear the onNewMessageCallback
  useEffect(() => {
    console.log({messages});
    
    const lastNewMessage = messages[0];

    if (lastNewMessage) {
      if (lastGuid !== lastNewMessage.id) {
        onNewMessageCallback();
      }
      setLastGuid(lastNewMessage.id);
    }
  }, [messages]);

  // TODO: Incluir sender
  // source_id
  // sender_name
  //above message_timestamp
  //<span style={{fontWeight: 'bold'}}>{chatMessage.sender_name}</span>

  function mapReverse (array, fn) {
    let arrey = array || [];
    return arrey.reduceRight(function (result, el) {
      result.push(fn(el));
      return result;
    }, []);
  }

  // TODO: Use e164 naming api that is used in app
  const handleSendMessage = async () => {
    setNewMessage("");
    const fromE164 = userId;
    const fromName = userName;
    const toE164 = activeChannelId;
    const toName = activeChannelTitle;
    const messageBody = newMessage;
    const messageType = 'text';
  
    try{
      nimbochatAPI.createMessage(fromE164, fromName, toE164, toName, messageBody, messageType);
    }catch (e){
      console.error('nimbochatAPI.createMessage error', e);
      alert(`Error sending a message, please try again. ${e}`);
    }
  };

  return (
    <>
      <div className='chatArea'>
        <div className='header'>
          <Avatar size='xl' name={activeChannelTitle} />
          <span className='title'>{activeChannelTitle}</span>
        </div>
        <div className='messages'>
          {mapReverse(messages, (chatMessage) => {
            return (
              <ChatBubble
                key={chatMessage.id}
                messageContent={chatMessage.message_body}
                timestamp={chatMessage.message_timestamp}
                direction={
                  chatMessage.from_e164 === activeChannelId
                    ? "incoming"
                    : "outgoing"
                }
              />
            );
          })}
        </div>
        <div className='messageBar'>
          <input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage()
              }
            }}
            type='text'
            placeholder='Write a message'
          />
          <a onClick={() => handleSendMessage()} href='#'>
            <SendIcon fill='rgb(49, 115, 254)' />
          </a>
        </div>
        <style jsx global>{`
          div.chatArea {
            display: flex;
            height: 100%;
            overflow: hidden;
            color: rgb(113, 114, 132);
            flex-direction: column;
            flex-grow: 1;
            min-width: 400px;
          }
          div.chatArea .header {
            flex-shrink: 0;
            min-height: 50px;
            background-color: white;
            border-bottom: 1px solid rgb(241, 244, 247);
            padding: 10px;
            flex-direction: row;
            display: flex;
            align-items: center;
          }
          div.chatArea .header .avatar {
            margin-left: 12px;
            margin-right: 12px;
          }
          div.chatArea .header .title {
            font-family: "Proxima Nova Semibold", "Helvetica", sans-serif;
            color: rgb(59, 70, 88);
            font-size: 15px;
          }
          div.chatArea .messages {
            display: flex;
            background-color: rgb(246, 249, 250);
            padding-left: 40px;
            padding-top: 20px;
            padding-right: 40px;
            padding-bottom: 100px;
            align-items: flex-start;
            display: inline-flex;
            flex-direction: column;
            overflow-y: auto;
            flex-grow: 1;
          }
          div.messageBar {
            position: absolute;
            left: 340px;
            right: 0;
            width: ${chatAreaSize - 140}px;
            height: 50px;
            bottom: 50px;
            background-color: white;
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          div.messageBar input {
            flex-grow: 1;
            margin-left: 18px;
            margin-right: 15px;
            font-size: 15px;
            border-top-style: hidden;
            border-right-style: hidden;
            border-left-style: hidden;
            border-bottom-style: hidden;
          }
          input:focus,
          textarea:focus,
          select:focus {
            outline: none;
          }
          div.messageBar a {
            width: 40px;
            margin-right: 18px;
          }
        `}</style>
      </div>
    </>
  );

  return <>Chat</>;
}