import React, { useEffect, useState } from 'react';
import { getRoomName } from '../../helpers/utils';
import { Text, Pane, majorScale, minorScale } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';
import teleconsultaAPI, {trackEvent, nimboxMetric} from "./../../helpers/teleconsultaAPI";

export default function ConsultationEndedScreen() {
  const [ isFa, setIsFa ] = useState(false);
  const { t } = useTranslation();


  useEffect(()=>{
    const roomName = getRoomName();

    async function fetchData(roomName) {
      try {
        const _roomInfo = await teleconsultaAPI.getTelehealthRoomInfo(roomName);
        console.log('_telehealthRoomInfo', _roomInfo);
        const orgId = _roomInfo.organization_id;
        const isFa = orgId === 23194 || orgId ===  114 || orgId === 25729 || orgId === 25684;
        setIsFa(isFa);
        trackEvent("USER_EXITED_MEETING", orgId);
        nimboxMetric("exited_teleconsultation", _roomInfo.id);
      }catch (e) {
        console.log("error requesting room info", roomName, e);
        trackEvent("USER_EXITED_MEETING", 0);
      }
    }


    fetchData(roomName);
  }, []);

  useEffect(()=> {
    if (isFa === false) return;

    window.setTimeout(function(){
      window.location.href = "http://www.fahorro.com";
    }, 5000);
  }, [isFa])

  return (
    <div className="visitor">
      {isFa ? (
        <Pane
          width={'100vw'}
          height={'100vh'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          paddingLeft={minorScale(1)}
          paddingRight={minorScale(1)}
        >
          <Pane className="fa-logo" width={'40%'}>
            <img width="100%" src="/images/falogo.png" />
          </Pane>
          <Pane marginTop={minorScale(1)} textAlign={'center'}>
            <h1>
              <i>Aún lejos,</i>
            </h1>
            <h1>
              <i>Te queremos bien</i>
            </h1>
          </Pane>
          <Text marginTop={majorScale(2)} marginBottom={majorScale(4)} size={600} color="muted">
  Teleorientación Finalizada
					</Text>
          <Text color="muted" size={600}>
  Envios de medicamento a domicilio
					</Text>
          <Text color="muted" size={600} marginBottom={majorScale(4)}>
            <a href="https://wwww.fahorro.com">www.fahorro.com</a>
          </Text>
          <span>
  powered by 
{' '}
<a href="https://nimbo-x.com/">nimbo</a>
          </span>
        </Pane>
      ) : (
        <React.Fragment>
          <img height="350px" src="/images/call-ended.png" />
          <h1>{t`Consultation Ended`}</h1>
          <h2>{t`Thank you!`}</h2>
        </React.Fragment>
      )}
      <style jsx>
        {`
					div {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						height: 90vh;
					}
					h1,
					h2 {
						font-family: "Proxima Nova Semibold", Helvetica, sans-serif;
						color: rgb(59, 70, 88);
					}
				`}
      </style>
    </div>
  );
}
