// This button has the design of nimbo-x

import React from 'react';
import { Button } from 'evergreen-ui';

export default function Nimbutton(props){
  return (
    <React.Fragment>
      <Button
        is={ props.is }
        className={props.appearance || "nimbox-button"}
        height={ props.height || 32 }
        appearance={ props.appearance || "default" }
        iconBefore={props.iconBefore}
        onClick={props.onClick}
        target={props.target}
        href={props.href}
        isLoading={props.isLoading}
      >
        {props.children}
      </Button>
      <style jsx global>
        {`
        .nimbox-button{
          background-image: none;
          border-radius: 5px;
          padding-top: 1px;
          padding-bottom: 1px;
          box-sizing: content-box;
          background-color: #0176fe;
          color: white;
          font-weight: 600;
        }
        .nimbox-button:hover{
          background-image: none !important;
          background-color: #107efe;
        }
        .nimbox-button:active{
          background-color: #2989ff !important;
        }
        `}
      </style>
    </React.Fragment>
  );
}