import React from "react";
// alternative fill rgb(49, 115, 254)
const SVG = ({
  style = {},
  fill = "#006CFF",
  width = "100%",
  viewBox = "-8 -9 35 35"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    fill={fill} 
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'>
    <path
      d='M0 17.0145l17.3333-8.5072L0 0v6.6167l12.4167 1.8906L0 10.3978z'
      fill-rule='evenodd'
    />
  </svg>
);

export default SVG;
