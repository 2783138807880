import React from "react";
import { useState } from "react";
import HangUpIcon from "../icons/HangUpIcon";


export default function HangUpButton(props) {
  return (
    <div className='option endOption' onClick={props.onClick}>
      <HangUpIcon />
      <style jsx>
        {`
            .endOption {
              background-color: red;
            }
          `}
      </style>
    </div>
  );
};