import React, {useState, useMemo, useEffect} from "react";
import teleconsultaAPI from "./../../helpers/teleconsultaAPI";
import {Spinner} from "evergreen-ui";

export default function CallHelpModal (props) {
  const [helpPatient, setHelPatient] = useState(false);
  const [helpDoctor, setHelpDoctor] = useState(false);
  const [helpDone, setHelpDone] = useState(false);
  const [roomInfo, setRoomInfo] = useState(null);
  
  useEffect(()=>{
    async function fetchData() {
      try {
        const _roomInfo = await teleconsultaAPI.getMeetingRoomInfo(props.roomName);
        console.log('_roomInfo', _roomInfo);
        setRoomInfo(_roomInfo);
      }catch (e) {
        console.log("error requesting room info", props.roomName, e);
      }
    }

    fetchData();

  }, [props.roomName]);


  const handleBack = () => {
    props.onBackPress && props.onBackPress();
  }

  const handlePatient = () => {
    setHelPatient(show => !show);
  }

  const handleDoctor = () => {
    setHelpDoctor(show => !show);
  }

  const handlePatientHelp = () => {
    props.onHelpPatientPress();
    setHelpDone(true);
  }

  const helpPatientUI = useMemo(()=> {
    if (helpDone) {
      return (<span>Mensaje enviado. El doctor te marcara a tu telefono celular</span>)
    }else{
      return (
        <a onClick={handlePatientHelp} href="#">
          Solicitar al doctor que me marque por llamada regular.
        </a>
      )
    }
  }, [helpDone]);

  const helpDoctorUI = useMemo(()=> {
    if (roomInfo === null) return <Spinner/>;

    const telephone = roomInfo.cellphone || roomInfo.landphone;

    if (telephone){
      return <span>Puedes contactar a tu paciente <b>{roomInfo.fullName}</b> por llamada regular marcado al <b>{roomInfo.cellphone}</b></span>
    }else{
      return <span>Lo sentimos el paciente <b>{roomInfo.fullName}</b> no proporciono telefono. Pero puede preguntar por el mismo en la seccion de chat.</span>
    }

  }, [roomInfo]);

  return (
  <React.Fragment>
  <div className="overlay"></div>
  <div className="help-modal">
    <h3>¿Problemas con la videollamada?</h3>
    {
        helpPatient
      ? helpPatientUI
      : helpDoctor
      ? helpDoctorUI
      : (
        <React.Fragment>
        <a onClick={handlePatient} href="#">Soy Paciente</a>
        <a onClick={handleDoctor} href="#">Soy Doctor</a>
        </React.Fragment>
      )
    }
    <a className="back" href="#" onClick={handleBack}>Cerrar esta ventana</a>
  </div>
  <style jsx>
  {`
    .back{
      color: black;
    }
    .overlay{
      position: absolute;
      z-index: 5;
      background-color: rgba(0,0,0,0.3);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    .help-modal{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      border: 1px solid grey;
      background: white;
      width: 500px;
      height: 350px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      z-index: 6;
      filter: drop-shadow(2px 2px 4px grey);
      border-radius: 8px;
      padding: 16px;
      box-sizing: border-box;
    }
    h1{
      margin-top:0;
      margin-bottom:0;
    }
  `}
  </style>
  </React.Fragment>
  );
}