import React from "react";
import {ChatContext} from "../../../contexts/ChatContext";
import nimbochatAPI from "../../../helpers/nimbochatAPI";
import Avatar from "./Avatar";
import {Spinner} from "evergreen-ui";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";


export default function Contacts(props) {
  const [patients, setPatients] = React.useState([]);
  const [filteredPatients, setFilteredPatients] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [currentSearch, setCurrentSearch] = React.useState('');

  const {
    setActiveSidebar,
    token,
    setActiveChannelId,
    setActiveChannelTitle,
    setRefetchIntervalMS
  } = React.useContext(ChatContext);
  
  async function componentDidMount() {
    let data = {patients: []};
    try {
      data = await nimbochatAPI.getAllPatientsForDoctor(token);
    }catch (e){
      console.log("Error loading patients", e);
    }finally{
      setLoading(false);
    }
    setPatients(data.patients);
  }

  React.useEffect(()=> {
    componentDidMount();
    setRefetchIntervalMS(null);
    return () => {
      setRefetchIntervalMS(5000);
    }
  }, []);


  const handleBack = () => {
    setActiveSidebar('channels');
  };

  const handlePatientPress = (patient) => {
    let cellphoneIsValid = /^\+\d{7,15}/.test(patient.e164);

    if (cellphoneIsValid){
      setActiveChannelId(patient.e164);
      setActiveChannelTitle(patient.name);
      setActiveSidebar('channels');
    }else{
      alert("Patient cellphone is invalid or not present");
    }
  };

  const handleSearch = (searchQuery) => {
    setCurrentSearch(searchQuery);
  };

  const patientList = React.useMemo(()=> {
    const _filteredPatients = patients.filter((patient) => {
      return patient.name
          .toUpperCase()
          .includes(currentSearch.toUpperCase());
    });

    return _filteredPatients;
  }, [patients, currentSearch]);

  const Row = ({ index, style }) => (
    <div onClick={()=> handlePatientPress(patientList[index])} key={patientList[index].id} style={style}>
      <div className="listItem" style={{height: '58px'}}>
        <div style={{ width: "40px" }}>
          <Avatar name={patientList[index].name} />
        </div>
        <div style={{ flexGrow: 1, flex: 1 }}>
            <div className='listItemTitle'>{patientList[index].name}</div>
            <div className='listItemSubtitle' style={{ marginTop: "4px" }}>
              {patientList[index].e164}
            </div>
        </div>
      </div>
    </div>
  );

  const ContactList = () => (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className="List"
          height={height}
          itemCount={patientList.length}
          itemSize={58}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );


  return (
    <>
    <div className="sidebar">
      <div className='searchContainer'>
        <a onClick={handleBack} href='#add'>
          {'<'}
        </a>
        <input 
          onChange={(e) => handleSearch(e.target.value)} 
          type='text' 
          placeholder='Search patient'
        ></input>
      </div>
      <div className='separator-80'></div>
      <div className='contactList'>
        { loading 
          ? (<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '110px'}}><Spinner/></div>)
          : null
        }
        <ContactList />
      </div>
    </div>
    <style jsx global>{`
      div.listItem {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgb(241, 244, 247);
      }
      div.listItem:hover{
        background-color: rgb(244, 247, 249);
        cursor: pointer;
      }
      div.sidebar .listItemTitle {
        font-family: "Proxima Nova Semibold", "Helvetica", sans-serif;
        color: rgb(59, 70, 88);
        font-size: 13px;
      }
      div.sidebar .listItemSubtitle {
        font-size: 11px;
        font-family: "Proxima Nova Regular";
        color: rgb(113, 114, 132);
      }
    `}
    </style>
    <style jsx>{`
      div.sidebar {
        min-width: 300px;
        max-width: 300px;
        flex-shrink: 0;
        flex-direction: column;
        display: flex;
        height: 100%;
      }
      div.contactList {
        flex-grow: 1;
        height: 100%;
        overflow-y: auto;
      }
      div.searchContainer {
        min-height: 50px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }
      div.searchContainer input {
        flex-grow: 1;
        height: 25px;
        padding: 2px;
        padding-left: 8px;
        border: 1px solid rgb(227, 235, 240);
        border-radius: 3px;
      }
      div.searchContainer a {
        height: 25px;
        padding: 2px;
        margin-right: 8px;
        padding-left: 1px;
        width: 25px;
        border: 1px solid #a9a9a9;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #a9a9a9;
        font-weight: bold;
      }
      div.searchContainer a:hover {
        background-color: rgb(244, 247, 249);
      }
      div.separator-80 {
        height: 1px;
        background-color: rgb(241, 244, 247);
        width: 88%;
        margin-left: 6%;
        margin-bottom: 2px;
        flex-shrink: 0;
      }
    `}
    </style>
    </>
  );
}