import React, { useEffect, useContext } from "react";
import Chat from "./_components/Chat";
import Channels from "./_components/Channels";
import Contacts from "./_components/Contacts";
import {ChatContextProvider, ChatContext} from "../../contexts/ChatContext";
import {useParameter, _try} from "../../helpers/utils";
import useLocalStorage from "../../helpers/useLocalStorage";


export default function NimbochatContainer (){
    return (
        <ChatContextProvider>
            <Nimbochat/>
        </ChatContextProvider>
    )
}

function Nimbochat(){
    const { userId,
            setUserId,
            token,
            setToken,
            setUserName,
            channels,
            activeSidebar,
            messages } = useContext(ChatContext);

    let [$user, $setUser] = useLocalStorage('user', null);

    // it will only fail (and become undefined) on incognito windows
    const localStorageType = _try(()=> typeof window.localStorage, undefined);
    const isIncognito = localStorageType === undefined;

    useEffect(()=> {
      if ($user === null) return;
      setUserName($user.userName);
      setToken($user.token);
      setUserId($user.userE164);
    }, [$user]);

    if (isIncognito){
      return (
        <>
        <div className='landingPublic'>
            <h1>Nimbochat Not Available on Incognito / Private Browsing</h1>
        </div>
        <style jsx>
        {`
            .landingPublic {
                padding: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                height: 100%;
            }
        `}
        </style>
        </>
    );
    }

    if (!token){
        return (
            <>
            <div className='landingPublic'>
                <h1>User not logged in</h1>
            </div>
            <style jsx>
            {`
                .landingPublic {
                    padding: 16px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    flex-grow: 1;
                    height: 100%;
                }
            `}
            </style>
            </>
        );
    }

    return (
        <div id="nimbochat">
          <div className='nimboChatContainer'>
            { activeSidebar === "channels" ? (<Channels channels={channels} />) : null }
            { activeSidebar === "contacts" ? (<Contacts />) : null }
            <Chat messages={messages} currentUserId={userId} />
            <style jsx>
              {`
                div.nimboChatContainer {
                  display: flex;
                  flex-direction: row;
                  align-items: stretch;
                  flex-grow: 1;
                  height: 100%;
                  font-family: "Proxima Nova Regular", "Helvetica", sans-serif;
                }
              `}
            </style>
            <style jsx global>
              {`
                #nimbochat {
                  display: flex;
                  flex-direction: column;
                  height: 100vh;
                  margin: 0;
                }
                body {
                  margin: 0;
                  overflow-y: hidden;
                  background-color: white;
                }
              `}
            </style>
          </div>
        </div>
      );
}