import React from "react";

const SVG = ({
  style = {},
  fill = "#FFF",
  width = "40%",
  viewBox = "0 0 512 512"
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}>
    <path
      fill={fill}
      d='M496 112.046h-48a15.9988 15.9988 0 00-11.36 4.64l-32 32 22.56 22.56 27.36-27.2H480v224h-25.44l-27.36-27.36a16.0015 16.0015 0 00-11.2-4.64h-48c-8.837 0-16 7.163-16 16v32c0 8.837-7.163 16-16 16H160v32h176c26.51 0 48-21.49 48-48v-16h25.44l27.36 27.36a16.0015 16.0015 0 0011.2 4.64h48c8.837 0 16-7.163 16-16v-256c0-8.836-7.163-16-16-16zM336 112.046v-32H48c-26.51 0-48 21.49-48 48v256h32v-256c0-8.837 7.163-16 16-16h288zM36.6967 436.6792l383.985-383.985 22.6272 22.6272-383.985 383.985z'
    />
  </svg>
);

export default SVG;
