import axios from "axios";

const nimbochatAPI = (function () {
  const __DEV__ = process.env.NODE_ENV === 'development';
  const __STAGING__ = window.location.hostname.includes('staging');
  const __PRODUCTION__ = !__DEV__ && !__STAGING__;

  const NIMBOX_API = __DEV__ ? "localhost:5000/": __STAGING__ ? 'https://nimbox-api-staging.herokuapp.com' : 'https://nimbox-api-production.herokuapp.com';

  const API_PORT = __DEV__ ? 3030 : 443;
	const API_PROT = __DEV__ ? 'http://' : 'https://';
	const API_HOST = window.location.hostname;
	const API_URL = `${API_PROT}${API_HOST}:${API_PORT}`;
  const NIMBOCHAT_API_URL = API_URL;

  // nimbox endpoints

  const getAccountInfo = async (nimboxToken) => {
    let endpoint = `${NIMBOX_API}/api/v1/accounts/me`;

    const {data} = await axios.get(endpoint,
      {
        timeout: 15000,
        headers: {
          'Authorization': `Bearer ${nimboxToken}`
        }
      }
    );

    return data;
  }

  const getCountryInfo = async (nimboxToken, countryID) => {
    let endpoint = `${NIMBOX_API}/api/v1/countries/${countryID}`;

    const {data} = await axios.get(endpoint,
      {
        timeout: 15000,
        headers: {
          'Authorization': `Bearer ${nimboxToken}`
        }
      }
    );

    return data;
  }

  // telechat endpoints

  const createMessage = async (fromE164, fromName, toE164, toName, messageBody, messageType, token) => {
    let endpoint = `${API_URL}/api/app/messages/create?token=${token}`;

    const {data} = await axios.post(endpoint,
      {
        fromE164: fromE164,
        fromName: fromName,
        toE164: toE164,
        toName: toName,
        messageBody: messageBody,
        messageType: messageType
      },
      {
        timeout: 15000
      }
    );

    return data;
  }

  const getUserInfo = async (userId, nchatToken) => {
    let endpoint = `${API_URL}/api/chat/${userId}?token=${nchatToken}`;

    const {data} = await axios.get(endpoint,
      {
        timeout: 15000
      }
    );

    return data;
  }

  const getChannels = async (nchatToken) => {
    let endpoint = `${API_URL}/api/app/messages/list?nimboxToken=${nchatToken}`;

    const {data} = await axios.get(endpoint,
      {
        timeout: 5000
      }
    );

    return data;
  }

  const getMessages = async (firstE164, secondE164, nimboxToken) => {
    let endpoint = `${API_URL}/api/app/messages/all?firstE164=${encodeURIComponent(firstE164)}&secondE164=${encodeURIComponent(secondE164)}&token=${nimboxToken}`;
    const {data} = await axios.get(endpoint,
      {
        timeout: 10000,
      }
    );

    return data;
  }

  const getAllPatientsForDoctor = async (nimboxToken) => {
    let endpoint = `${API_URL}/api/app/doctor/patients?nimboxToken=${nimboxToken}`;

    const {data} = await axios.get(endpoint,
      {
        timeout: 10000,
      }
    );

    return data;
  }

  return {
    getAccountInfo,
    getCountryInfo,
    getUserInfo,
    getChannels,
    getMessages,
    createMessage,
    getAllPatientsForDoctor,
    NIMBOCHAT_API_URL
  }
})();

export default nimbochatAPI;