import React from 'react';
import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar } from 'react-avataaars';
import { getLocalMedia, _try } from '../helpers/utils';
import { useQuery } from 'react-query';
import teleconsultaAPI from '../helpers/teleconsultaAPI';
import Hashids from 'hashids';
import { Pane } from 'evergreen-ui';
import ReactPlayer from 'react-player/youtube';

import AvatarMaleBeardIcon from "../components/icons/AvatarMaleBeardIcon";
import AvatarMaleIcon from "../components/icons/AvatarMaleIcon";
import AvatarFemaleIcon from "../components/icons/AvatarFemaleIcon";
import WaitingRoomIllustration from "../components/illustrations/WaitingRoomIllustration";


const avatarOptions = {
	eyebrow: 'default',
	eyes: 'default',
	mouth: 'smile',
	facialHair: 'light',
	accessories: 'prescription02',
	top: 'shortHair'
};

const hasher = new Hashids('N1MB0X3C4R3S0FT', 0, 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789');

export default function Lobby() {
	const [onlineDoctors, setOnlineDoctors] = useState(0);

	let { id: hashID } = useParams();
	const [ localStream, setLocalStream ] = useState(null);
	const localVideoRef = useRef(null);
	const { status: meetingStatus, data: meeting, error: meetingError } = useQuery(
		'turn',
		async () => {
			let response = {}
			try {
				response = await teleconsultaAPI.refreshLobby(hashID);
			}catch (e){
				console.log('refreshLobby error', e);
			}
			console.log(JSON.stringify(response))
			const meeting = {
				people_in_front: 3,
				...response.telehealth_waiting_room
			};

			return meeting;
		},
		{
			refetchInterval: 60000,
			initialData: undefined
		}
	);

	const [ orgId, setOrgID ] = useState(0);
	const [ orgName, setOrgName ] = useState('');
	const [ bannerMobileURL, setBannerMobileURL] = useState('https://nimbox-production.s3.amazonaws.com/publico/placeholder.png');
	const [ bannerDesktopURL, setBannerDesktopURL] = useState('https://nimbox-production.s3.amazonaws.com/publico/placeholder.png');
	const [ videoURL, setVideoURL] = useState('');

	useEffect(() => {
		//23194
		async function fetchAndSetOnlineDoctors(orgId){
			const jsonData = await teleconsultaAPI.getOnlineDoctors(orgId);
			setOnlineDoctors(jsonData.online);
		}

		const orgId = hasher.decode(hashID)[0];
		fetchAndSetOnlineDoctors(orgId);
		setOrgID(orgId);
		getLocalMedia()
			.then((stream) => {
				setLocalStream(stream);
			})
			.catch((error) => {
				// TODO: Set a fake stream for error
				// Maybe a video of failed to get camera
				console.log('error getting media', error);
			});
	}, []);

	useEffect(
		() => {
			if (localStream === null) return;

			localVideoRef.current.srcObject = localStream;
			setTimeout(() => {
				if (localVideoRef.current) {
					localVideoRef.current.srcObject = localStream;
					let promise = undefined;
					promise = localVideoRef.current.play();
				}
			}, 1500);
		},
		[ localStream ]
	);

	useEffect(()=> {
		if (orgId === 0) return;

		async function getOrgInfo(){
			try {
				const jsonData = await teleconsultaAPI.getOrganizationInfo(orgId);
				const orgName = _try(()=> jsonData.name, '');
				setOrgName(orgName);

				const configuration = _try(() => jsonData.waiting_room_configuration, {});
				setBannerMobileURL(_try(() => configuration.banner_mobile, 'https://nimbox-production.s3.amazonaws.com/publico/placeholder.png'));
				setBannerDesktopURL(_try(() => configuration.banner_desktop, 'https://nimbox-production.s3.amazonaws.com/publico/placeholder.png'));
				setVideoURL(_try(() => configuration.video_url, ''));
			} catch (e) {
				console.log("Couldn't get org info");
			}
		}

		getOrgInfo();
	}, [orgId]);

	const AVERAGE_WAITING_TIME = 5;

	const waitingTime = useCallback(() => {
		// plus one because 'onlineDoctors' may be zero doctors available
		const people_in_front = meeting && meeting.people_in_front;
		const people = people_in_front || 0;

		const waitingTime = Math.floor((AVERAGE_WAITING_TIME*Math.max(people,1)) / (Math.max(onlineDoctors,1)));
		const waitingTimeCappedAt30Minutes = waitingTime < 30 ? waitingTime : 30;

		return waitingTimeCappedAt30Minutes;
	}, [onlineDoctors, meeting]);

	if (meeting && meeting.status === 'expired') {
		return <h1>Cita Expirada</h1>;
	}

	if (meeting && meeting.status === 'accepted') {
		window.location.href = meeting.consent_policy_url;
		return <React.Fragment />;
	}

	const people_in_front = meeting && meeting.people_in_front;

	const isFa = orgId === 23194 || orgId === 25729 || orgId === 25684 || orgId === 114;

	const teleconsulta_word = isFa ? 'teleorientación' : 'teleconsulta';
	// meeting.status === pending

	const orgIDLogos = {
		114: "/images/falogo.png",
		23194: "/images/falogo.png",
		25684: "/images/faderma.png",
		25729: "/images/fanutricion.png"
	}

	return (
		<React.Fragment>
		<div className="header">
			<picture>
				<source srcSet={bannerDesktopURL} media="(min-width: 768px)" />
				<img src={bannerMobileURL} alt="Banner promocional"/>
			</picture>
		</div>
		<div className="lobby">
			<div className="content">
				<div className="queue">
					<div className="queue-wrapper">
						<div className="subHeader">
							<div className="subHeaderLeft">
							{
								isFa ? (
										<Pane className="logo" height={'55px'} marginTop={'-16px'} marginBottom={16}>
											<img height="100%" src={orgIDLogos[orgId]} />
										</Pane>
								)
								: (
									<Pane className="logo" height={'55px'} marginTop={'-16px'} marginLeft={'8px'} marginBottom={16}>
										<h3>{orgName}</h3>
									</Pane>
								)
							}
							</div>
							<div className="subHeaderRight">
							</div>
						</div>
						<h1>Sala de Espera</h1>
						<span>
						{ people_in_front
							? people_in_front === 1
								? (<React.Fragment>Hay <b>{people_in_front} paciente</b> enfrente de ti</React.Fragment>)
								: (<React.Fragment>Hay <b>{people_in_front} pacientes</b> enfrente de ti</React.Fragment>)
							: `En un momento te atenderemos`
						}
						{
							onlineDoctors === 0
							? <React.Fragment>. Te avisaremos cuando sea tu turno.</React.Fragment>
							: <React.Fragment> y tu tiempo aproximado de espera es de {waitingTime()} min.</React.Fragment>
						}
						</span><br/>
						<span>
							Te enviaremos un mensaje por Whatsapp y correo cuando sea tu turno.
						</span>
						<div className="patientQueue">
							<div className="patient current">
								<div className="status">
									<span>TÚ</span>
								</div>
								<video playsInline autoPlay muted ref={localVideoRef} />
							</div>
							{meeting &&
								[ ...Array(meeting.people_in_front).keys() ].map((index) => {

									const activePatient = index === meeting.people_in_front - 1 ? 'active' : '';

									if (index > 6) return null;

									return (
										<div key={index} className={'patient ' + activePatient}>
												{
													index === 0 ? <AvatarFemaleIcon width="64px"  /> : null
												}
												{
													index === 1 ? <AvatarMaleIcon width="64px"  /> : null
												}
												{
													index === 2 ? <AvatarMaleBeardIcon width="64px"  /> : null
												}
												{
													index === 3 ? <AvatarMaleIcon width="64px"  /> : null
												}
												{
													index === 4 ? <AvatarMaleBeardIcon width="64px"  /> : null
												}
												{
													index === 5 ? <AvatarFemaleIcon width="64px"  /> : null
												}
												{
													index === 6 ? (<div className="status"><span>+{meeting.people_in_front-6}</span></div>) : null
												}
										</div>
									);
								})}
						</div>
					</div>
				</div>
				<div className="marketing-video">
					<div className="video-player-wrapper">
						<ReactPlayer
							className="video-player"
							url={videoURL}
							width='100%'
							height='100%'
							controls muted playing loop
						/>
					</div>
				</div>
			</div>
		</div>
		<div className="footer">
			<WaitingRoomIllustration height="100%"/>
			<span className="developed-by">
				<b>Desarollado por </b>
				&nbsp;<img height="15px" alt="nimbo" src="/images/nimbo-logo.png"/>
			</span>
		</div>
		<style jsx global>
			{`
				ul {
					font-size: 16px;
					margin-left: 0px;
					padding-left: 20px;
					margin-top: 0px;
					font-style: italic;
				}
				html, body{
					height: 100%;
				}
				body {
					padding: 0;
					margin: 0;
					font-family: 'Proxima Nova Regular', Helvetica, sans-serif;
					background-color: rgb(250, 252, 254);
					color: rgb(59, 70, 88);
				}
				#root{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					height: 100%;
				}
				.lobby {
					max-width: 100vw;
					display: flex;
					flex-direction: column;
					justify-content: center;
					flex: 1 0 auto;
				}
				.footer {
					display: flex;
					flex-direction: row;
					align-items: flex-end;
					width: 100%;
					z-index: -1;
					flex-shrink: 0;
					font-weight: 600;
					font-family: 'Proxima Nova Semibold',Helvetica,sans-serif;
				}
				.footer .developed-by {
					margin: 0 .5rem 1rem .5rem;
					width: 100%;
					flex-grow: 1;
					display: flex;
					justify-content: center;
				}
				.logo{
					display:flex;
					align-items: flex-end;
				}
				.header{
					background-color: rgb(240,242,244);
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
				}
				.header picture,
				.header img {
					height: 100%;
					width: 100%;
					object-fit: fill;
				}
				.content {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: center;
					width: 100%;
					height: 100%;
				}
				h1,
				h2,
				h3 {
					margin: 0;
					font-family: 'Proxima Nova Semibold', Helvetica, sans-serif;
				}
				.subHeader{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: flex-start;
					margin: 1rem 0;
				}
				.subHeaderLeft{
					min-width: 350px;
					width: 350px;
				}
				.subHeaderRight{
					min-width: 350px;
					width: 350px;
				}
				video {
					width: 64px;
					height: 64px;
					border-radius: 64px;
					object-fit: cover;
				}
				.patientQueue {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					margin-top: 1rem;
				}
				.patient {
					width: 64px;
					height: 64px;
					margin-right: 16px;
					margin-bottom: 16px;
				}
				.patient.current .status{
					background-color: rgba(40, 120, 245, .4);
				}
				.patient.current .status span{
					color: rgb(250, 252, 254);
				}
				.patient .avatar {
					filter: blur(2px) grayscale(95%);
				}
				.patient .status {
					position: absolute;
					width: 64px;
					height: 64px;
					display: flex;
					justify-content: center;
					align-items: center;
					z-index: 2;
					background-color: rgb(217, 228, 235);
					border-radius: 64px;
				}
				.patient .status span {
					color: rgb(18, 39, 68);
					width: 100%;
					text-align: center;
					font-family: 'Proxima Nova Semibold', Helvetica, sans-serif;
					font-weight: bold;
					font-size: 18px;
				}
				.queue {
					width: 100%;
					display: flex;
					justify-content: center;
					align-content: center;
				}
				.queue-wrapper {
					margin-left: 8px;
					min-width: 350px;
					width: 350px;
					margin-right: 8px;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
				.queue h1 {
					margin-bottom: 12px;
				}
				.queue span {
					line-height: 1.2;
				}
				.marketing-video {
					width: 100%;
					align-content: center;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 1rem;
					margin-left: 0;
				}
				.marketing-video .video-player-wrapper {
					box-shadow: none;
					overflow: hidden;
					padding-bottom: 56.25%;
					position: relative;
					height: 0;
					width: 100%;
					margin: 1rem;
				}
				.marketing-video .video-player-wrapper .video-player {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
				}
				ul{
					line-height: 1.5;
				}
				@media (min-width: 992px) {
					.header picture,
					.header img {
						display:flex;
						justify-content: center;
						width: 70%;
					}
				}
				@media (min-width: 768px) {
					.header {
						height: 90px;
					}
					.footer {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 50%;
					}
					.queue {
						margin: 0;
						width: 50%;
					}
					.marketing-video {
						margin: 0;
						width: 50%;
						height: 100%;
						margin-left: -2%;
					}
					.marketing-video .video-player-wrapper {
						box-shadow: 30px 30px 0px rgb(0, 74, 160);
						width: 80%;
					}
				}
			`}
		</style>
		</React.Fragment>
	);
}
