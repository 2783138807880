import React from 'react';
import { useState, useEffect, useRef, createContext, useContext, useCallback } from 'react';
import Peer from 'peerjs';
import { PreflightContext } from '../contexts/PreflightContext';
import { getRoomName } from '../helpers/utils';
import ConsultationEndedScreen from '../components/meeting/ConsultationEndedScreen';
import BusyRoomScreen from '../components/meeting/BusyRoomScreen';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
Sentry.init({dsn: "https://c0b44582c98b4241828e3c280da2708f@o417367.ingest.sentry.io/5316922"});

export const PeerContext = createContext(null);

const __DEV__ = process.env.NODE_ENV === 'development';

const API_PORT = __DEV__ ? 3030 : 443;
const API_PROT = __DEV__ ? 'http://' : 'https://';
const API_HOST = window.location.hostname;
const API_URL = `${API_PROT}${API_HOST}:${API_PORT}`;

export default function PeerContextProvider(props) {
	window.contador = 0;
	const [roomName, setRoomName] = useState(null); // meetingid
	const [userName, setUserName] = useState(null); // doctor / patient
	const [remoteUserName, setRemoteUserName] = useState(null); // doctor / patient
	const [remoteConnection, setRemoteConnection] = useState(null);
	const [dataChannelOpen, setDataChannelOpen] = useState(false);
	const [peerError, setPeerError] = useState(false);
	const [busyRoomError, setBusyRoomError] = useState(false); // this could be bundled upside
	const [connectionEnded, setConnectionEnded] = useState(false);
	const [localVideoStopped, setLocalVideoStopped] = useState(false);
	const [remoteVideoStopped, setRemoteVideoStopped] = useState(false);
	const [iceServers, setIceServers] = useState(null);
	const peerRef = useRef(null);
	const mediaConnectionRef = useRef(null); // call it as WebRTC's media streams WebRTCMediaConnection
	const { t } = useTranslation();

	const [roomMessages, setRoomMessages] = useState([
		{
			kind: 'system',
			content: t`Hi! You can also chat with your doctor here`
		}
	]);

	const { localStream, remoteVideoRef, setRemoteStream } = useContext(PreflightContext);

	function peerjs_connectTo(username) {
		const connection = peerRef.current.connect(username, { serialization: 'json' });

		if(connection){
			connection.on('open', function () {
				console.log('Connected to', username);
				setDataChannelOpen(true);
			});

			connection.on('data', function (data) {
				console.log('Received', data);
				handleNewMessage(data);
			});

			connection.on('error', (error) => {
				Sentry.captureException(error, roomName);
			});
		}
		else {

			Sentry.captureMessage('custom null connection');
		}
	}

	const peerjs_connectAs = (username) => {
		const namespacedName = 'nimbo-' + roomName.hashCode() + '_' + username;

		let peerServerConfig = {
			host: window.location.hostname,
			path: '/peerjs',
			secure: true,
			port: 443
		};

		if (__DEV__) {
			peerServerConfig = {
				host: window.location.hostname, // localhost
				path: '/peerjs',
				port: 3030
			};
		}

		console.log(iceServers);

		if(peerRef.current) {
			peerRef.current.destroy();
		}

		peerRef.current = new Peer(namespacedName, {
			...peerServerConfig,
			config: { iceServers: iceServers }
		});

		peerRef.current.on('open', function (id) {
			console.log('My peer ID is: ' + id);
			setUserName(id);
		});

		peerRef.current.on('error', (error) => {
			//unavailable-id
			if (error.type === 'unavailable-id') {
				// Maybe don't try to put setBusyRoomError as fast
				// The user may be trying to change browsers quickly
				if (username === 'guest') {
					// Here it means, "host" and "guest" are already taken.
					// Meaning a third person is trying to connect
					//setBusyRoomError(true);
					if(window.contador <= 3){
						//setCount(window.contador + 1)
						window.contador = window.contador + 1
						setTimeout(() => peerjs_connectAs('guest'), 1000);
					}
					else{
						peerjs_connectAs('host');
						//setCount(0)
						window.contador = 0
					}
				} else {
					peerjs_connectAs('guest');
				}
			} else if (error.type === 'webrtc') {
				Sentry.captureMessage(error.type);
				setTimeout(() => peerjs_connectAs(username), 1000);
			}
			else if (error.type === 'peer-unavailable') {
				setPeerError(true);
			} else {
				// TODO: Handle this type of error with UI
				console.log('ERROR!', error);
				console.log('ERROR TYPE!', error.type);
				// peer-unavailable (retry call)

				// I think we have to relaunch the connection in here
				setTimeout(() => peerjs_connectAs(username), 1000);
			}
		});

		peerRef.current.on('connection', (remoteConn) => {
			console.log('Saving remote connection');
			setRemoteConnection(remoteConn);
		});
	};

	useEffect(
		() => {
			if (!peerError) return;

			console.log('retrying connection to ', remoteUserName);
			// retry in 1.5 seconds
			setTimeout(() => {
				// Setup Data Channel
				peerjs_connectTo(remoteUserName);

				// --------------------
				// Setup MediaChannel
				console.log('Trying a call');

				const mediaConnection = peerRef.current
					.call(remoteUserName, localStream)
					.on('stream', (remoteStream) => {
						setRemoteStream(remoteStream);
					});
				mediaConnection.on('close', () => {
					console.log('Host Disconnected');
					//window.location.reload();
					setPeerError(true);
				});
				mediaConnectionRef.current = mediaConnection;
				// --------------------
			}, 2000);
			setPeerError(false);
		},
		[ peerError ]
	);

	// Get IceServers on componentDidMount
	useEffect(() => {
		const getIceServers = async () => {
			try {
				let r = await fetch(`${API_URL}/api/iceServers`);
				let j = await r.json();
				setIceServers(j);
			} catch (e) {
				console.log('error on fetchingIceServers');
				setIceServers([
					{ url: 'stun:stun.l.google.com:19302' },
					{
						url: 'turn:numb.viagenie.ca',
						username: 'oliveira@ymail.com',
						credential: '1a2b3c4d5e'
					}
				]);
			}
		};
		getIceServers();
	}, []);

	// Start Connection only after getting user media
	// TODO: Provide option to connect anyway using only text chat
	useEffect(
		() => {
			if (localStream === null) return;

			const roomName = getRoomName();
			setRoomName(roomName);
		},
		[ localStream ]
	);

	// Setup PeerJS connection after roomName is set
	useEffect(
		() => {
			if (roomName === null) return;
			if (iceServers === null) return;

			// userName generation
			// first try to be the host
			// if host is taken, try be the guest
			peerjs_connectAs('host');
		},
		[ roomName, iceServers ]
	);

	// Execute this after saving the username
	// which happens after connecting to the room,
	// which happens after getting localmedia
	useEffect(
		() => {
			if (userName === null) return;

			// TODO: Make it so if one reloads the page, they can rejoin de videochat smoothly

			// (Current Strategy)
			// Hacky, but works. Just refresh webpage on call close.

			// (Alternative Strategy)
			// One is to have the guest continue calling after a host disconnects (every x seconds)

			// (Better Strategy)
			// Use a socket.io server to negotiate reconnection

			// Guest comes second, so guest calls host
			if (userName.includes('_guest')) {
				// Si tu eres el guest, automaticamente asumes, que el host ya esta conectado.
				// Si tu eres el segundo, quiere decir que el primero ya esta en el server.

				const remoteUser = 'nimbo-' + roomName.hashCode() + '_host';
				console.log('Saving remote user ', remoteUser);
				setRemoteUserName(remoteUser);

				// Setup Data Channel
				peerjs_connectTo(remoteUser);

				// --------------------
				// Setup MediaChannel
				console.log('Trying a call');

				const mediaConnection = peerRef.current.call(remoteUser, localStream).on('stream', (remoteStream) => {
					setRemoteStream(remoteStream);
				});
				mediaConnection.on('close', () => {
					console.log('Host Disconnected');
					//window.location.reload();
					setPeerError(true);
				});
				mediaConnectionRef.current = mediaConnection;
				// --------------------
			}

			// Host comes first, so he just waits around and answers
			if (userName.includes('_host')) {
				const remoteUser = 'nimbo-' + roomName.hashCode() + '_guest';
				console.log('Saving remote user ', remoteUser);

				setRemoteUserName(remoteUser);

				// No sabes si el guest esta conectado o no

				// En cuanto se conecto host, no sabemos si guest esta conectado
				// En cuanto entro el primero, no hay garantia de que el segundo este

				// MediaChannel Setup
				peerRef.current.on('call', function (mediaConnection) {
					// Aqui adentro significa que me estan marcado
					// Por ende, guest ya esta en el server tambien

					// DataChannel Setup
					peerjs_connectTo(remoteUser);

					mediaConnection.answer(localStream); // Answer the call with an A/V stream.
					mediaConnection.on('stream', function (remoteStream) {
						setRemoteStream(remoteStream);
					});
					mediaConnection.on('close', () => {
						console.log('Guess Disconnected');
						//window.location.reload();
					});
					mediaConnectionRef.current = mediaConnection;
				});
			}
		},
		[ userName ]
	);

	useEffect(
		() => {
			if (dataChannelOpen === false) return;
			if (remoteConnection === null) return;

			console.log(remoteConnection);
			// First message only passes after a little bit of delay
			setTimeout(() => {
				remoteConnection.send('Remote client connected');
			}, 3000);
		},
		[dataChannelOpen, remoteConnection]
	);

	const handleAudioToggle = () => {
		// Disable audio locally
		localStream.getAudioTracks().forEach((track) => {
			track.enabled = !track.enabled;
		});
	};

	function handleVideoToggle(muted) {
		if (muted) {
			remoteConnection.send('STOPVIDEO');
			setLocalVideoStopped(true);
		} else {
			remoteConnection.send('STARTVIDEO');
			setLocalVideoStopped(false);
		}
	}

	useEffect(
		() => {
			localStream.getVideoTracks().forEach((track) => {
				track.enabled = !localVideoStopped;
			});
		},
		[ localVideoStopped ]
	);

	function handleHangUp() {
		// Send a ENDCALL to the other user
		remoteConnection.send('ENDCALL');

		// Close media socket connection
		mediaConnectionRef.current.close();

		// Reflect the changes on the UI
		endCallLocally();
	}

	function endCallLocally() {
		localStream.getTracks().forEach(function (track) {
			track.stop();
		});
		setConnectionEnded(true);
	}

	function handleNewMessage(data) {
		if (data === 'STOPVIDEO') {
			setRemoteVideoStopped(true);
		}
		if (data === 'STARTVIDEO') {
			setRemoteVideoStopped(false);
		}
		if (data === 'ENDCALL') {
			endCallLocally();
		}
		if (data['kind']) {
			setRoomMessages((messages) => [...messages, data]);
		}
	}

	function sendMessage(message, kind = 'remote') {
		const newMessage = {
			kind: 'local',
			content: message
		};
		const payload = {
			...newMessage,
			kind: kind
		};

		setRoomMessages((roomMessages) => [...roomMessages, newMessage]);
		remoteConnection.send(payload);
	}

	const peerAPI = {
		handleAudioToggle,
		handleVideoToggle,
		handleHangUp,
		endCallLocally,
		remoteVideoStopped,
		localVideoStopped,
		roomMessages,
		remoteConnection,
		sendMessage
	};

	if (busyRoomError) {
		return <BusyRoomScreen />;
	}

	if (connectionEnded) {
		return <ConsultationEndedScreen />;
	}

	return <PeerContext.Provider value={peerAPI}>{props.children}</PeerContext.Provider>;
}
