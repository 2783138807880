import React from "react";

//width="614px" height="272px"

const SVG = ({
  width = "614px",
  height = "272px",
  viewBox = "0 0 614 272"
}) => (
<svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg">
  <defs>
    <path id="a" d="M0 .863h176.55v167.699H0z"/>
    <path id="c" d="M0 .863h176.55v167.699H0z"/>
    <path id="e" d="M0 .863h176.55v167.699H0z"/>
    <path id="g" d="M.708.635h87.676V83H.708z"/>
  </defs>
  <g fill="none" fillRule="evenodd">
    <path fill="#122744" d="M192 231l32.224 120H228l-25.786-120zM83.795 231L58 351h3.787L94 231z"/>
    <path fill="#0A0B1E" d="M170 231l32.213 120H206l-25.81-120zM105.793 231L80 351h3.777L116 231z"/>
    <g transform="translate(55 71)">
      <path d="M172.304 151.14c-7.45-6.83-18.547-14.775-21.678-31.154-4.014-21.137-11.727-70.485-16.24-98.787C131.41 2.558 118.685.863 112.089.863H64.342c-6.595 0-19.32 1.695-22.286 20.336-4.574 28.717-11.464 73.225-16.383 99.507-2.978 15.972-14.107 23.717-21.433 30.433-8.721 7.977-3.01 17.423 8.241 17.423h151.582c11.24 0 16.982-9.446 8.241-17.423" fill="#D0E6FF" mask="url(#b)"/>
    </g>
    <path d="M219.435 220.337c-6.746-6.184-16.795-13.378-19.628-28.208-3.635-19.139-8.011-76.63-12.098-102.255-2.696-16.88-14.216-18.413-20.189-18.413-4.97 0-43.722.37-48.683.37-5.971 0-17.493 1.534-20.18 18.413-4.14 26.003-7.537 78.741-11.99 102.538-2.696 14.461-12.772 21.473-19.407 27.555-7.896 7.223-2.724 15.775 7.462 15.775h137.251c10.177 0 15.375-8.552 7.462-15.775" fillOpacity=".3" fill="#99C8FE"/>
    <g>
      <path fill="#122744" d="M382 231l32.224 120H418l-25.786-120zM273.795 231L248 351h3.787L284 231z"/>
      <path fill="#0A0B1E" d="M360 231l32.213 120H396l-25.81-120zM295.793 231L270 351h3.777L306 231z"/>
      <g transform="translate(245 71)">
        <path d="M172.304 151.14c-7.45-6.83-18.547-14.775-21.678-31.154-4.014-21.137-11.727-70.485-16.24-98.787C131.41 2.558 118.685.863 112.089.863H64.342c-6.595 0-19.32 1.695-22.286 20.336-4.574 28.717-11.464 73.225-16.383 99.507-2.978 15.972-14.107 23.717-21.433 30.433-8.721 7.977-3.01 17.423 8.241 17.423h151.582c11.24 0 16.982-9.446 8.241-17.423" fill="#D0E6FF" mask="url(#d)"/>
      </g>
      <path d="M409.435 220.337c-6.746-6.184-16.795-13.378-19.628-28.208-3.635-19.139-8.011-76.63-12.098-102.255-2.696-16.88-14.216-18.413-20.189-18.413-4.97 0-43.722.37-48.683.37-5.971 0-17.493 1.534-20.18 18.413-4.14 26.003-7.537 78.741-11.99 102.538-2.696 14.461-12.772 21.473-19.407 27.555-7.896 7.223-2.724 15.775 7.462 15.775h137.251c10.177 0 15.375-8.552 7.462-15.775" fillOpacity=".3" fill="#99C8FE" />
    </g>
    <g>
      <path fill="#122744" d="M574 231l32.224 120H610l-25.786-120zM465.795 231L440 351h3.787L476 231z"/>
      <path fill="#0A0B1E" d="M552 231l32.213 120H588l-25.81-120zM487.793 231L462 351h3.777L498 231z"/>
      <g transform="translate(437 71)">
        <path d="M172.304 151.14c-7.45-6.83-18.547-14.775-21.678-31.154-4.014-21.137-11.727-70.485-16.24-98.787C131.41 2.558 118.685.863 112.089.863H64.342c-6.595 0-19.32 1.695-22.286 20.336-4.574 28.717-11.464 73.225-16.383 99.507-2.978 15.972-14.107 23.717-21.433 30.433-8.721 7.977-3.01 17.423 8.241 17.423h151.582c11.24 0 16.982-9.446 8.241-17.423" fill="#D0E6FF" mask="url(#f)"/>
      </g>
      <path d="M601.435 220.337c-6.746-6.184-16.795-13.378-19.628-28.208-3.635-19.139-8.011-76.63-12.098-102.255-2.696-16.88-14.216-18.413-20.189-18.413-4.97 0-43.722.37-48.683.37-5.971 0-17.493 1.534-20.18 18.413-4.14 26.003-7.537 78.741-11.99 102.538-2.696 14.461-12.772 21.473-19.407 27.555-7.896 7.223-2.724 15.775 7.462 15.775h137.251c10.177 0 15.375-8.552 7.462-15.775" fillOpacity=".3" fill="#99C8FE" />
    </g>
    <g>
      <path d="M32.071 160C6.916 117.076 2.722 64.56 17.581 17.058 19.99 9.353 24.538.533 32.578.023 41.48-.543 47.061 9.41 49.522 18.006c8.624 30.162 6.79 62.203 4.883 93.525-1.174 19.292-7.786 35.785-22.334 48.469M59 177.114c9.074-29.365 32.172-53.997 60.789-64.833 6.655-2.516 15.111-3.952 20.073 1.165 4.869 5.024 3.369 13.415.246 19.684-5.043 10.128-13.233 18.264-21.39 26.073-7.42 7.102-15.132 14.232-24.516 18.36-9.385 4.127-26.622 5.178-35.202-.449" fill="#21CD89"/>
      <path d="M-98 67c-.01.019.139.15.44.394.364.279.812.625 1.362 1.05 1.287.96 3.056 2.277 5.298 3.946l3.84 2.827c1.378 1.064 2.822 2.294 4.406 3.573 3.124 2.604 6.822 5.407 10.54 8.931 1.898 1.721 3.887 3.52 5.955 5.396 2.061 1.885 4.044 4.015 6.189 6.125 4.348 4.167 8.56 9.03 13.088 14.05 8.766 10.295 17.764 22.169 26.051 35.39 8.168 13.309 14.802 26.636 20.126 39.056 2.482 6.284 4.963 12.212 6.76 17.952.935 2.853 1.961 5.574 2.732 8.254l2.18 7.721c1.501 4.89 2.363 9.44 3.301 13.389.442 1.983.903 3.818 1.239 5.524.3 1.706.572 3.271.82 4.688l1.174 6.483.336 1.684c.08.377.134.567.154.567.019-.002.008-.2-.035-.586-.056-.451-.129-1.01-.216-1.698a6791.6 6791.6 0 01-.955-6.528c-.219-1.426-.457-3-.72-4.716-.304-1.716-.733-3.561-1.144-5.554-.88-3.976-1.688-8.55-3.143-13.47l-2.111-7.768c-.752-2.695-1.757-5.433-2.675-8.305-1.762-5.773-4.219-11.737-6.685-18.059-5.287-12.49-11.924-25.894-20.125-39.257-8.324-13.28-17.385-25.184-26.232-35.481-4.572-5.024-8.826-9.88-13.22-14.03-2.166-2.104-4.175-4.225-6.258-6.1-2.095-1.86-4.106-3.645-6.027-5.353-3.767-3.497-7.513-6.264-10.687-8.826-1.606-1.254-3.07-2.464-4.473-3.501-1.425-1-2.729-1.919-3.913-2.751l-5.432-3.768-1.43-.955c-.325-.21-.496-.309-.51-.294" fill="#1A2E35"/>
      <path d="M-132 185.675c.011.039.607-.06 1.73-.287 1.127-.202 2.76-.636 4.906-.99 4.263-.833 10.495-1.77 18.243-2.222 15.413-.98 37.321.837 59.244 9.923 21.932 9.085 38.668 23.282 48.834 34.862 5.136 5.793 8.86 10.854 11.273 14.451 1.26 1.766 2.1 3.225 2.753 4.165.63.954.979 1.443 1.014 1.423.035-.02-.244-.55-.81-1.546-.585-.981-1.366-2.482-2.57-4.294-2.303-3.686-5.94-8.849-11.03-14.75-10.065-11.792-26.868-26.234-49.008-35.402-22.131-9.173-44.259-10.868-59.752-9.668-7.791.565-14.034 1.634-18.283 2.605-2.139.428-3.76.933-4.872 1.211-1.106.302-1.681.48-1.672.519M-38.994-10c-.02.006.013.23.098.665.103.5.237 1.133.402 1.925.394 1.768.949 4.235 1.654 7.37 1.45 6.4 3.666 15.63 6.446 27.027 5.548 22.797 13.615 54.186 22.018 88.97 4.173 17.404 7.953 34.028 11.211 49.186 3.196 15.17 5.922 28.864 7.952 40.413 1.088 5.762 1.904 11 2.678 15.593.794 4.59 1.404 8.554 1.878 11.797.493 3.175.882 5.674 1.163 7.464l.33 1.936c.083.437.135.658.155.654.019 0 .008-.229-.033-.669l-.216-1.954-.941-7.496a354.687 354.687 0 00-1.68-11.835c-.712-4.606-1.473-9.86-2.509-15.635-1.923-11.575-4.566-25.297-7.707-40.495-3.2-15.182-6.95-31.825-11.126-49.238-8.407-34.799-16.602-66.154-22.358-88.893L-36.39-.147l-1.871-7.32c-.214-.78-.385-1.405-.517-1.896-.123-.425-.196-.64-.216-.637M20.009 239c.02.004.072-.172.156-.517l.343-1.538c.295-1.455.7-3.44 1.21-5.942 1.04-5.162 2.42-12.655 3.994-21.941 3.15-18.566 6.885-44.352 9.181-73.028A732.541 732.541 0 0036.9 95.533c.204-12.463.095-23.694-.286-33.112-.113-4.713-.416-8.967-.619-12.703-.187-3.74-.446-6.953-.682-9.576l-.546-6.045c-.069-.627-.127-1.144-.172-1.566-.044-.353-.078-.531-.098-.531-.018 0-.025.185-.018.54.013.426.033.945.056 1.578l.327 6.057c.168 2.627.362 5.84.488 9.577.14 3.737.386 7.99.446 12.695.275 9.41.297 20.621.038 33.062-.33 12.44-.981 26.11-2.095 40.43-2.291 28.63-5.893 54.4-8.835 72.986a1450.862 1450.862 0 01-3.63 21.994c-.417 2.518-.75 4.514-.996 5.98-.09.627-.167 1.138-.23 1.56-.044.352-.058.537-.038.541" fill="#1A2E35"/>
      <path d="M17.006 245c.04.013.265-.5.659-1.484.422-1.1.976-2.533 1.665-4.32.36-.952.756-1.995 1.187-3.125.5-1.126 1.043-2.338 1.622-3.631.584-1.31 1.202-2.703 1.855-4.178.655-1.478 1.501-2.97 2.304-4.576.823-1.597 1.683-3.27 2.58-5.007.986-1.696 2.011-3.458 3.073-5.279 2.031-3.706 4.655-7.387 7.188-11.398A269.968 269.968 0 0158.183 177.7c7.326-8.163 14.803-15.47 21.851-21.845 3.633-3.063 6.96-6.122 10.322-8.687 1.652-1.316 3.248-2.587 4.786-3.81l4.543-3.346c2.865-2.173 5.564-3.989 7.938-5.577 2.352-1.627 4.405-2.983 6.132-4.015l3.917-2.481c.889-.578 1.346-.899 1.328-.936-.021-.034-.52.216-1.449.729-1.028.583-2.367 1.346-4.04 2.29-1.767.978-3.86 2.279-6.254 3.857-2.417 1.54-5.157 3.313-8.067 5.451l-4.604 3.296-4.846 3.769c-3.4 2.542-6.766 5.586-10.436 8.638-7.117 6.356-14.652 13.675-22.013 21.875a257.23 257.23 0 00-19.06 24.48c-2.522 4.047-5.13 7.768-7.133 11.513l-3.03 5.342c-.876 1.76-1.72 3.45-2.526 5.068-.78 1.626-1.606 3.136-2.235 4.634l-1.78 4.232-1.538 3.685c-.396 1.148-.764 2.205-1.094 3.173-.605 1.821-1.086 3.286-1.46 4.406-.319 1.01-.468 1.548-.429 1.559M19.018 80.003c-.293.153 3.042 7.216 7.45 15.775 4.409 8.559 8.221 15.373 8.514 15.22.293-.156-3.04-7.217-7.45-15.776-4.407-8.559-8.221-15.373-8.514-15.22M47.974 53.003c-.307-.145-3.231 4.884-6.528 11.232-3.304 6.352-5.728 11.617-5.42 11.762.306.149 3.23-4.882 6.529-11.232 3.3-6.35 5.724-11.617 5.42-11.762" fill="#1A2E35"/>
      <path d="M89.001 148.913c.078.416 4.93-.772 10.981-1.294 6.052-.558 10.989-.245 11.018-.67.038-.37-4.937-1.342-11.099-.776-6.164.533-10.98 2.38-10.9 2.74" fill="#1A2E35"/>
      <g transform="translate(-28 232)">
        <path d="M88.384.635L74.412 74.572C73.487 79.46 69.24 83 64.292 83H23.015c-5.039 0-9.338-3.668-10.162-8.67L.708.635h87.676z" fill="#0176FE" mask="url(#h)"/>
      </g>
      <path fill="#0176FE" d="M55.443 257H-23.79L-28 233h88z"/>
      <path fill="#FAFAFA" d="M56 257l-1.658 9h-76.131L-23 257z"/>
    </g>
  </g>
</svg>
);
export default SVG;