import React from "react";

export default function BusyRoomScreen(){
  return (
    <div className='visitor'>
      <img height='350px' src='/images/busy-room.jpg' />
      <h1>Llamada ocupada</h1>

      <h2>Teleconsulta en proceso</h2>
      <style jsx>
        {`
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 90vh;
        }
        h1,
        h2 {
          font-family: "Proxima Nova Semibold", Helvetica, sans-serif;
          color: rgb(59, 70, 88);
        }
      `}
      </style>
    </div>
  );
}