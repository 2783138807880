import React from 'react';
import { useState, useEffect, useRef, createContext } from 'react';
import { Pane, Text, Spinner, majorScale, minorScale, Icon, Button, Heading } from 'evergreen-ui';
import browser from 'browser-detect';
import useCameraCheck from './media/CameraCheck';
import useMicrophoneCheck from './media/MicrophoneCheck';
import useWebRTCCheck from './media/WebRTCCheck';
import usePermissionsCheck from './media/PermissionsCheck';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import * as Sentry from '@sentry/react';

Sentry.init({dsn: "https://c0b44582c98b4241828e3c280da2708f@o417367.ingest.sentry.io/5316922"});
export const PreflightContext = createContext(null);

const __DEV__ = process.env.NODE_ENV === 'development';

const capitalized = function(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function PreflightContextProvider(props) {
	let SKIP_CHECKS = false;

	if (__DEV__) {
	//SKIP_CHECKS = true;
	}

	const [cookies, setCookie] = useCookies();


	if (cookies.browserHasPassedPreflight === 'yes'){
		SKIP_CHECKS = true;
	}


	const [ localStream, setLocalStream ] = useState(null);
	const [ remoteStream, setRemoteStream ] = useState(null);
	const [ localStreamError, setLocalStreamError ] = useState(false);
	const localVideoRef = useRef(null);
	const remoteVideoRef = useRef(null);

	const [ preflightOK, setPreflightOK ] = useState(false);

	const [ isBrowserSupported, setIsBrowserSupported ] = useState(null);
	const [ displayBrowserTroubleShoot, setDisplayBrowserTroubleshoot ] = useState(false);
	const [ isMobile, setIsMobile ] = useState(false);
	const [ isCheckingBrowserSupport, setIsCheckingBrowserSupport ] = useState(true);
	const [ checkingBrowserIsDone, setCheckingBrowserIsDone ] = useState(false);

	const { setIsCameraChecking, isCameraDone, cameraCheckingUI } = useCameraCheck();

	const { setIsMicrophoneChecking, isMicrophoneDone, microphoneCheckingUI } = useMicrophoneCheck();

	const { setIsChecking: setIsWebRTCChecking, isDone: isWebRTCDone, checkingUI: webRTCCheckingUI } = useWebRTCCheck();

	const {
		setIsChecking: setIsPermissionsChecking,
		isDone: isPermissionsDone,
		checkingUI: permissionsUI
	} = usePermissionsCheck(setLocalStream);

	const [ isTestingVideo, setIsTestingVideo ] = useState(false);
	const [ isRecording, setIsRecording ] = useState(false);
	const [ isRecorded, setIsRecorded ] = useState(false);
	const [ isPlayed, setIsPlayed ] = useState(false);
	const [ recordingTroubleshoot, setRecordingTroubleshoot ] = useState(false);

	const currentBrowser = browser();
	const { t } = useTranslation();

	// Unomment this useEffect below to skip checks
	useEffect(() => {
			if (SKIP_CHECKS) setIsPermissionsChecking(true);
	}, [ ]);

	const preflightAPI = {
		localStream,
		localStreamError,
		remoteStream,
		setRemoteStream,
		localVideoRef,
		remoteVideoRef,
		setLocalStreamError
	};

	useEffect(
		() => {
			if (!isCheckingBrowserSupport) return;

			console.log(currentBrowser);

			let browserIsSupported = false;
			let browserNeedsUpdate = false;

			if (currentBrowser.name === 'chrome') {
				browserIsSupported = true;
				if (currentBrowser.versionNumber < 69) {
					browserNeedsUpdate = true;
				}
			}

			if (currentBrowser.name === 'safari') {
				browserIsSupported = true;
				if (currentBrowser.versionNumber < 12) {
					browserNeedsUpdate = true;
				}
			}

			if (currentBrowser.name === 'firefox') {
				browserIsSupported = true;
				if (currentBrowser.versionNumber < 71) {
					browserNeedsUpdate = true;
				}
			}

			console.log('isBrowserSupported before mobile check', browserIsSupported);
			console.log('browserNeedUpdate before mobile check', browserNeedsUpdate);

			// Only support stock browsers for iOS (safari) and Android (chrome)
			
			if (currentBrowser.mobile) {
				setIsMobile(true);
				if (currentBrowser.os.toLowerCase().includes('android')) {
					browserIsSupported = true;
					// if (currentBrowser.name.toLowerCase() !== 'chrome') {
					// 	browserIsSupported = false;
					// }
				}
				if (currentBrowser.os.toLowerCase().includes('os x')) {
					browserIsSupported = true;
					//uncomment for validate with safari
					// if(currentBrowser.name){
					// 	if (currentBrowser.name.toLowerCase() !== 'safari') {
					// 		browserIsSupported = false;
					// 	}
					// }
					// else{
					// 	browserIsSupported = false;
					// }
					
				}
			}

			console.log('isBrowserSupported after mobile check', browserIsSupported);

			setTimeout(() => {
				setIsCheckingBrowserSupport(false);
				//resulto de verificar si el browser es supported o no
				if (browserIsSupported && !browserNeedsUpdate) {
					setIsBrowserSupported(true);
				} else {
					setIsBrowserSupported(false);
				}
			}, 500);
		},
		[ isCheckingBrowserSupport ]
	);

	useEffect(
		() => {
			if (isBrowserSupported === null) return;

			if (isBrowserSupported === false) {
				console.log('isBrowserSupported ', isBrowserSupported);
				setDisplayBrowserTroubleshoot(true);
				return;
			}

			setCheckingBrowserIsDone(true);
			setIsCameraChecking(true);
		},
		[ isBrowserSupported ]
	);

	useEffect(
		() => {
			if (isCameraDone === false) return;

			setIsMicrophoneChecking(true);
		},
		[ isCameraDone ]
	);

	useEffect(
		() => {
			if (isMicrophoneDone === false) return;

			setIsWebRTCChecking(true);
		},
		[ isMicrophoneDone ]
	);

	useEffect(
		() => {
			if (isWebRTCDone === false) return;

			setIsPermissionsChecking(true);
		},
		[ isWebRTCDone ]
	);

	useEffect(
		() => {
			if (isPermissionsDone === false) return;

			if (SKIP_CHECKS) {
				setPreflightOK(true);
				return;
			}

			if (currentBrowser.name !== 'safari') {
				setTimeout(() => setIsTestingVideo(true), 1500);
				return;
			}

			console.log('Successfully passed al checks');
			setTimeout(() => setPreflightOK(true), 1500);
		},
		[ isPermissionsDone ]
	);

	useEffect(
		() => {
			if (isTestingVideo === false) return;
		},
		[ isTestingVideo ]
	);

	useEffect(
		() => {
			if (isRecorded === false) return;

			let recording = document.getElementById('recording');
			recording.play();
			recording.loop = true;
			setTimeout(() => {
				setIsPlayed(true);
			}, 5000);
		},
		[ isRecorded ]
	);

	useEffect( 
		() => {
			if (preflightOK){
				setCookie('browserHasPassedPreflight', 'yes', { path: '/' });
				console.log('saved cookie baby');
			}
		}
	, [preflightOK]);

	function startRecording(stream) {
		let recorder = new MediaRecorder(stream);
		let data = [];
		const miliseconds = 5000;

		recorder.ondataavailable = (event) => data.push(event.data);
		recorder.start();
		console.log(recorder.state + ' for ' + miliseconds / 1000 + ' seconds...');

		function wait(delayInMS) {
			return new Promise((resolve) => setTimeout(resolve, delayInMS));
		}

		let stopped = new Promise((resolve, reject) => {
			recorder.onstop = resolve;
			recorder.onerror = (event) => reject(event.name);
		});

		let recorded = wait(miliseconds).then(() => recorder.state == 'recording' && recorder.stop());

		return Promise.all([ stopped, recorded ]).then(() => data);
	}

	if (isTestingVideo) {
		return (
			<Pane
				marginTop={majorScale(4)}
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Heading size={600} marginBottom={majorScale(1)}>
					{t`Camera & Audio Test`}
				</Heading>
				<Pane display={'flex'} flexDirection={'row'}>
					<video
						className={isRecorded ? 'preview' : 'preview active'}
						id="preview"
						width="320"
						height="240"
						autoPlay
						muted
					/>
					<video
						className={isRecorded ? 'recording active' : 'recording'}
						id="recording"
						width="320"
						height="240"
						onClick={(e) => {
							e.target.pause();
							console.log(e);
						}}
					/>
					<Pane display={'block'} width={'320px'} height={'240px'} />
				</Pane>
				<Pane display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
					<Text marginTop={majorScale(1)}>{t`This test records your video and audio for 5 seconds.`}</Text>
					<Text>{t`At the end you should be able to see and listen yourself.`}</Text>
					<Button
						disabled={isRecorded || isRecording ? true : false}
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						width={'170px'}
						textAlign={'center'}
						marginTop={majorScale(2)}
						marginBottom={majorScale(1)}
						appearance="primary"
						onClick={() => {
							setIsRecording(true);
							console.log('test');
							let preview = document.getElementById('preview');
							let recording = document.getElementById('recording');
							let runPreview = new Promise((resolve) => {
								preview.srcObject = localStream;
								preview.captureStream = preview.captureStream || preview.mozCaptureStream;
								preview.onplaying = resolve;
							});
							runPreview
								.then(() => {
									console.log('is playing');
									return startRecording(preview.captureStream());
								})
								.then((recordedChunks) => {
									console.log('already recorded');
									setIsRecording(false);
									let recordedBlob = new Blob(recordedChunks, { type: 'video/webm' });
									recording.src = URL.createObjectURL(recordedBlob);
									setIsRecorded(true);
									console.log(
										'Successfully recorded ' +
											recordedBlob.size +
											' bytes of ' +
											recordedBlob.type +
											' media.'
									);
								})
								.catch((e) => console.log('error ', e));
						}}
					>
						{isRecording ? <Spinner size={majorScale(3)} /> : isRecorded ? 'Playing' : t`Start Test`}
					</Button>
					{isRecording ? (
						''
					) : isRecorded ? (
						''
					) : (
						<Button
							onClick={() => {
								setPreflightOK(true);
								setIsTestingVideo(false);
							}}
							appearance="minimal"
						>
							{t`Skip Test`}
						</Button>
					)}
					{isPlayed ? (
						<React.Fragment>
							<Button
								disabled={recordingTroubleshoot ? true : false}
								appearance="primary"
								justifyContent={'center'}
								alignItems={'center'}
								width={'170px'}
								marginBottom={majorScale(1)}
								onClick={() => {
									setPreflightOK(true);
									setIsTestingVideo(false);
								}}
							>
								{t`Audio/Video is OK`}
							</Button>
							<Button
								disabled={recordingTroubleshoot ? true : false}
								justifyContent={'center'}
								alignItems={'center'}
								width={'170px'}
								onClick={() => setRecordingTroubleshoot(true)}
							>
								{t`Audio/Video is NOT OK`}
							</Button>
							{recordingTroubleshoot ? (
								<React.Fragment>
									<Pane
										display={'flex'}
										justifyContent={'center'}
										alignItems={'center'}
										flexDirection="column"
										paddingLeft={majorScale(1)}
										paddingRight={majorScale(1)}
									>
										<Text textAlign={'center'} marginTop={majorScale(1)}>
											{t`Your device can't make teleconsultations`}
										</Text>
										<Text textAlign={'center'} marginTop={majorScale(1)}>
											{t`Please try in a different computer or cellphone`}
										</Text>
										<Text
											textAlign={'center'}
											size={400}
											marginTop={majorScale(1)}
											textAlign={'center'}
										>
											<i>{window.location.href}</i>
										</Text>
										<Button
											intent="warning"
											justifyContent={'center'}
											alignItems={'center'}
											marginTop={majorScale(2)}
											onClick={() => {
												setPreflightOK(true);
												setIsTestingVideo(false);
											}}
										>
											{t`Continue anyway`}
										</Button>
									</Pane>
								</React.Fragment>
							) : (
								''
							)}
						</React.Fragment>
					) : (
						''
					)}
					<style jsx global>
						{`
							.preview {
								position: absolute;
								border: 1px solid black;
							}
							.recording {
								position: absolute;
							}
							.active {
								z-index: 1;
							}
						`}
					</style>
				</Pane>
			</Pane>
		);
	}

	if (preflightOK) {
		return <PreflightContext.Provider value={preflightAPI}>{props.children}</PreflightContext.Provider>;
	}

	/*eslint-disable */
	return (
		<Pane marginTop={majorScale(4)}>
			<Pane display={'flex'} justifyContent={'center'} alignItems={'center'} marginBottom={majorScale(1)}>
				<Heading size={600} marginBottom={majorScale(1)}>
					{t`Browser Test`}
				</Heading>
			</Pane>
			<Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
				<Text size={500} marginRight={minorScale(2)}>
					1 - {t`Checking Browser`}
				</Text>
				{isCheckingBrowserSupport ? (
					<Spinner size={minorScale(4)} />
				) : isBrowserSupported ? (
					<Icon size={minorScale(4)} icon="tick" color="success" />
				) : (
					<Icon size={minorScale(4)} icon="cross" color="danger" />
				)}
			</Pane>
			{displayBrowserTroubleShoot ? (
				<React.Fragment>
					<Pane
						paddingLeft={majorScale(1)}
						paddingRight={majorScale(1)}
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						flexDirection={'column'}
						marginTop={majorScale(2)}
					>
						<Text size={500} marginTop={majorScale(2)} textAlign={'center'}>
							{t`Your browser is incompatible`}
						</Text>
						<Text size={400} marginTop={majorScale(2)} fontWeight={'bold'}>
							{t`Possible Solutions`}
						</Text>
						{isMobile ? (
							<React.Fragment>
								<Text size={400} marginTop={majorScale(2)} textAlign={'center'}>
									a) {t`Use the default browser in your mobile`}
									<br />
									{t`Safari on iOS`}
									<br />
									{t`Chrome on Android`}
								</Text>
								<Text size={400} marginTop={majorScale(2)} textAlign={'center'}>
									a) {t`Open your teleconsultation on a computer`}
								</Text>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Text size={400} marginTop={majorScale(2)}>
									a) {t`Please update your browser`}
								</Text>
								<Text size={400} marginTop={majorScale(2)} textAlign={'center'}>
									b) {t`Try opening your teleconsultation in a compatible browser`}
									<br />
									<br />
									<a href="https://chrome.google.com">{t`Chrome 69 or newer`}</a>
									<br />
									<a href="https://firefox.com">{t`Firefox 71 or newer`}</a>
								</Text>
								<Text size={400} marginTop={majorScale(2)}>
									c) {t`Try opening your teleconsultation on a cellphone or different computer`}
								</Text>
							</React.Fragment>
						)}
					</Pane>
				</React.Fragment>
			) : (
				''
			)}
			{cameraCheckingUI}
			{microphoneCheckingUI}
			{webRTCCheckingUI}
			{permissionsUI}
			<Pane display={'flex'} justifyContent={'center'} alignItems={'center'}>
				<Text textAlign={'center'} size={400} marginTop={majorScale(2)} textAlign={'center'}>
					<i>{window.location.href}</i>
				</Text>
			</Pane>
		</Pane>
	);
	/*eslint-enable */
}
