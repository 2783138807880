String.prototype.hashCode = function() {
	var hash = 0;
	if (this.length == 0) {
		return hash;
	}
	for (var i = 0; i < this.length; i++) {
		var char = this.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash = hash & hash; // Convert to 32bit integer
	}
	return Math.abs(hash);
};

function _try(func, fallbackValue) {
	try {
		var value = func();
		return value === null || value === undefined ? fallbackValue : value;
	} catch (e) {
		return fallbackValue;
	}
}

function getLocalMedia() {
	return new Promise((resolve, reject) => {
		navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
		const mediaOptions = {
			video: {
				width: { min: 320, ideal: 640, max: 640 },
				height: { min: 240, ideal: 480, max: 480 }
			},
			audio: true
		};
		navigator.getUserMedia(
			mediaOptions,
			(stream) => {
				resolve(stream);
			},
			(error) => {
				console.log('Error getting media', error);
				reject(error);
			}
		);
	});
}

function useParameter(param_name) {
	const url_string = window.location.href;
	const url = new URL(url_string);
	const c = url.searchParams.get(param_name);
	return c;
}

function getRoomName() {
	const url_string = window.location.href;
	const url = new URL(url_string);
	const path = decodeURIComponent(url.pathname);
	const room_name = path.substring(1);
	return room_name;
}

export { _try, useParameter, getLocalMedia, getRoomName };
