import React from "react";

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  viewBox = "0 0 86 86"
}) => (
  <svg 
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path id="a" d="M0 .73h69.27V70H0z"/>
      </defs>
        <g fill="none" fillRule="evenodd">
          <circle fill="#D9E4EB" cx="43" cy="43" r="43"/>
          <path d="M58.724 35.026S65.08 55.2 56.061 60.51c-8.177 4.815-21.85-2.682-22.65-5.235-.798-2.553-4.423-24.27.78-25.9 9.25-2.895 21.819-3.02 24.533 5.65" fill="#F49C84"/>
          <path d="M37.323 48.184c-.769-1.896-2.11-4.182-4.213-2.894-.743.455-1.156 1.426-1.446 2.367-.963 3.13 1.284 7.96 4.357 7.514 1.5-.218 2.33-1.996 2.94-3.462" fill="#F49C84"/>
          <path d="M39.048 47.082c1.007 1.78 3.516 3.055 6.267 3.355 5.029.548 10.655-1.65 15.662-1.105 1.117.123 1.188 5.29 1.171 6.937-.02 1.957-.434 4.035-1.43 5.72-.975 1.644-2.774 2.621-4.581 3.24-3.808 1.308-7.921 1.338-11.86.68-1.93-.324-3.58-.53-5.254-1.548-1.691-1.03-3.223-2.153-4.512-3.676-1.449-1.713-2.584-3.806-4.487-5.08-2.23-1.493-4.626-1.383-5.87-4.191-.755-1.703-.851-3.614-.934-5.474-.164-3.664-.123-7.342.097-11.03.536-9.035 7.872-13.124 15.268-14.023 3.33-.405 8.177-.53 11.528-.639 2.702-.088 6.423-1.198 8.91.166 4.334 2.374 2.84 9.903-1.548 11.292-4.793 1.516-12.247-.148-17.237.477-3.474.436-3.462 5.45-3.537 6.061-.416 3.335.848 6.187 2.347 8.838" fill="#122744"/>
          <path d="M32.002 44.48c-.82.31-1.459 1.018-1.876 1.835-1.956 3.833 2.604 8.991 6.674 7.23 1.493-.647.943-4.63-2-8.462-.655-.852-1.98-.914-2.798-.603" fill="#F49C84"/><path d="M51.832 41.602s2.844 5.14 2.368 6.208c-.637 1.429-3.184.75-3.184.75" stroke="#C45F47" strokeWidth=".75"/>
          <path d="M49.59 53.903l6.797-.819s.508 4.818-2.55 5.544c-3.757.895-4.247-4.725-4.247-4.725" fill="#FFF"/>
          <path d="M56.297 40.447c.095.413.196.83.32 1.235.044.137.197.24.34.193a.282.282 0 00.194-.34c-.11-.41-.25-.815-.39-1.215-.043-.119-.167-.21-.296-.168a.244.244 0 00-.168.295" fill="#0A1B43"/>
          <path d="M54.758 38.093s1.43-1.971 3.548-.46" stroke="#0A1B43" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M45.048 43.042c.072.419.15.84.252 1.253.035.14.2.237.34.192a.281.281 0 00.192-.339 16.991 16.991 0 00-.319-1.235c-.035-.12-.172-.208-.297-.166-.134.042-.191.162-.168.295" fill="#0A1B43"/>
          <path d="M46.24 39.14s-2.325-.764-4.698 1.998" stroke="#0A1B43" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>
    );
export default SVG;
        