import React from "react";

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  viewBox = "0 0 86 86"
}) => (
<svg 
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg">
  <defs>
    <path id="a" d="M.01.246h13.993v13.14H.01z"/>
  </defs>
  <g fill="none" fillRule="evenodd">
    <circle fill="#D9E4EB" cx="43" cy="43" r="43"/>
    <path d="M62.707 26.474c4.407 4.798 10.498 24.178 2.106 27.435-7.689 2.984-18.469.049-21.304-11.294-2.981-11.92 7.614-28.751 19.198-16.14" fill="#F49C84"/>
    <path d="M46.911 44.015c6.214-6.131 3.994-11.435 3.994-11.435S64.908 32.202 70 27.178c0 0-3.644-11.185-13.5-7.021-5.592-2.898-14.466-.114-18.095 5.707-5.656 9.068-.001 19.26 3.35 21.136" fill="#122744"/>
    <path d="M49.77 41.108c.009-.395-.445-1.54-1.62-1.977-2.07-.768-3.356 1.99-3.123 4.18.146 1.375.589 2.45 1.465 3.516 1.153 1.405 2.672 1.72 3.508-.013" fill="#F49C84"/>
    <g transform="translate(32 12)">
      <mask id="b" fill="#fff">
      </mask>
      <path d="M13.044 10.051c1.176-3.091 1.391-6.222-.039-8.42C12.156.33 9.843-.395 7.76 1.012c-1.165.786-1.633 2.267-1.879 3.538-1.246-.263-2.767-.4-3.931.384-2.085 1.408-2.33 3.86-1.483 5.163 1.343 2.06 3.912 3.137 6.994 3.29" fill="#122744" mask="url(#b)"/>
    </g>
    <path d="M42.95 44.339c-.01-.215-.015-.43-.014-.644C42.96 36.555 56 20.22 56 20.22c-11.444-1.334-18.29 3.5-20.188 11.563-.373 1.582-.384 5.2-.384 5.2-7.284-2.515-9.668-2.124-12.075.142-2.194 2.065-1.753 6.622.345 10.522.08.15 1.162 2.823 1.162 2.823-5.683-1.789-19.388 3.344-6.519 14.36 2.154 1.845 4.48 2.853 7.184 1.358.635 1.988 4.293 3.445 4.293 3.445s22 6.4 24.222-8.497c.836-5.612-8.393-14.374-11.09-16.797" fill="#122744"/>
    <path d="M62.511 40c.365.938.596 1.782.44 2.14-.59 1.35-2.951.707-2.951.707" stroke="#C45F47" strokeWidth=".75" strokeLinecap="round"/>
    <path d="M59 45.726L64.972 45s.445 4.27-2.24 4.915C59.43 50.707 59 45.726 59 45.726" fill="#FFF"/>
    <path d="M65.029 36.567c.022.178.049.355.08.53.03.176.058.352.1.524.033.127.092.24.182.309.085.064.21.092.305.05.207-.087.328-.353.275-.634-.034-.18-.085-.35-.131-.522a11.955 11.955 0 00-.183-.604.37.37 0 00-.205-.207.28.28 0 00-.224.023.372.372 0 00-.164.184l-.035.108a.604.604 0 000 .24" fill="#0A1B43"/>
    <path d="M64 33.832s1.21-1.272 3-.297" stroke="#0A1B43" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M56.04 38.443c.018.216.042.43.069.644.024.18.043.36.08.537a.54.54 0 00.182.306c.084.064.209.091.303.051a.431.431 0 00.236-.235.724.724 0 00.04-.395c-.025-.177-.068-.346-.106-.518a9.83 9.83 0 00-.128-.51c-.03-.105-.077-.21-.157-.266a.296.296 0 00-.417.07.508.508 0 00-.101.316" fill="#0A1B43"/>
    <path d="M57 35.093s-1.978-.73-4 1.907" stroke="#0A1B43" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
  </g>
</svg>
  );
export default SVG;
        